import {useContext, useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import ToolbarCustom from '../../../_metronic/layout/components/toolbar/toolbars/ToolbarCustom'

import { ContentContext } from '../../context'
import NewsDetailModal from './NewsDetailModal'
import moment from 'moment'

const NewsPage = () => {
  
  const { news, newsLoaded, fetchNews, createNews, updateNews, deleteNews } = useContext(ContentContext)

  const [currentNews, setCurrentNews] = useState(null)
  const [sortBy, setSortBy] = useState('name')
  const [sortOrder, setSortOrder] = useState('asc')
  const [filteredNews, setFilteredNews] = useState([])
  const [search, setSearch] = useState('')

  useEffect(() => {
    if(!newsLoaded) {
      fetchNews()
    }
  }, [newsLoaded, fetchNews])

  const onCreateClick = () => {
    setCurrentNews({
      id: '',
      title: {},
      text: {},
      link: {},
      published: false,
    })
  }

  const onEditClick = (news) => {
    setCurrentNews(news)
  }

  const onDetailSubmit = async (data) => {
    if(!data.id) {
      await createNews(data)
    } else {
      await updateNews(data.id, data)
    }
    setCurrentNews(null)
  }

  const onDetailDelete = async () => {
    await deleteNews(currentNews.id)
    setCurrentNews(null)
  }

  const itemWithId = (array, id) => {
    return array.find(i => i.id === id)
  }

  const onSortClick = (property) => {
    if(sortBy !== property) {
      setSortBy(property)
      setSortOrder('asc')
    } else {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    }
  }

  useEffect(() => {
    let r = [...news]
    if(sortBy === 'title') {
      r.sort((a, b) => {
        if(a.title.fr < b.title.fr) return sortOrder === 'asc' ? -1 : 1
        if(a.title.fr > b.title.fr) return sortOrder === 'asc' ? 1 : -1
        return 0
      })
    } else if(sortBy === 'updated') {
      r.sort((a, b) => {
        if(a.meta.updated < b.meta.updated) return sortOrder === 'asc' ? -1 : 1
        if(a.meta.updated > b.meta.updated) return sortOrder === 'asc' ? 1 : -1
        return 0
      })
    } else if(sortBy === 'status') {
      r.sort((a, b) => {
        if(a.published < b.published) return sortOrder === 'asc' ? -1 : 1
        if(a.published > b.published) return sortOrder === 'asc' ? 1 : -1
        return 0
      })
    }
    if(search) {
      let searchComponents = search.split(/\s/g).map(s => s.trim()).filter(s => s)
      r = r.filter(news => {
        let foundCount = 0
        for(let searchComponent of searchComponents) {
          if(news.title.fr.toLowerCase().includes(searchComponent.toLowerCase())) {
            foundCount++
          }
        }
        return foundCount === searchComponents.length
      })
    }
    setFilteredNews(r)
  }, [news, sortOrder, sortBy, search])

  const togglePublish = async (newsItem) => {
    await updateNews(newsItem.id, { ...newsItem, published: !newsItem.published })
  }

  return (
    <>
      <ToolbarCustom
        pageTitle={'News'}
        primaryButton={newsLoaded && { onClick: onCreateClick, title: 'Add' }}
        filterElements={[
        <div
          data-kt-search-element='form'
          className='w-100 position-relative'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen021.svg'
            className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 translate-middle-y ms-0'
          />
          <input
            type='text'
            className='form-control form-control-flush ps-10'
            name='search'
            value={search}
            placeholder='Search...'
            data-kt-search-element='input'
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>]}
        loading={!newsLoaded} />
      {/* begin::Row */}
      <div className='row g-6 g-xl-9 mt-1'>
        {/* begin::Table */}
        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
          {/* begin::Table head */}
          <thead>
            <tr className='fw-bold text-muted'>
              <th className='w-15px'>
              </th>
              <th onClick={() => onSortClick('title')} className='min-w-150px'>{`Title${sortBy === 'title' ? sortOrder === 'desc' ? ' ↑' : ' ↓' : ''}`}</th>
              <th onClick={() => onSortClick('updated')} className='min-w-150px'>{`Updated${sortBy === 'updated' ? sortOrder === 'desc' ? ' ↑' : ' ↓' : ''}`}</th>
              <th onClick={() => onSortClick('status')} className='min-w-140px'>{`Status${sortBy === 'status' ? sortOrder === 'desc' ? ' ↑' : ' ↓' : ''}`}</th>
              <th className='min-w-100px text-end'>Actions</th>
            </tr>
          </thead>
          {/* end::Table head */}
          {/* begin::Table body */}
          <tbody>
          { newsLoaded && filteredNews.map((newsItem) => 
            <tr key={`news_${newsItem.id}`}>
              <td>
              </td>
              <td>
                <div className='d-flex align-items-center'>
                  <div className='d-flex justify-content-start flex-column'>
                    <div className={`${newsItem.published ? 'text-primary' : 'text-dark'} fw-bold fs-6`}>
                      { newsItem.title.fr }
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div className='d-flex align-items-center'>
                  <div className='d-flex justify-content-start flex-column'>
                    {/* <div className='text-dark fw-bold text-hover-primary fs-6'>
                      { moment(newsItem.meta.updated).format("DD/MM/YYYY HH:mm") }
                    </div> */}
                    <div className='text-dark fw-semibold d-block fs-7'>
                      { moment(newsItem.meta.updated).format("DD/MM/YYYY HH:mm") }
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div className='text-dark fw-semibold d-block fs-7'>
                  { newsItem.published ? 'Published' : 'Draft' }
                </div>                
              </td>
              <td>
                <div className='d-flex justify-content-end flex-shrink-0'>
                  <button
                  onClick={() => togglePublish(newsItem)}
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                  >
                    <KTSVG path={newsItem.published ? '/media/icons/duotune/general/gen040.svg' : '/media/icons/duotune/general/gen043.svg'} className='svg-icon-3' />
                  </button>

                  <button
                  onClick={() => onEditClick(newsItem)}
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                  >
                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                  </button>
                </div>
              </td>
            </tr>
          )}
          </tbody>
          {/* end::Table body */}
        </table>
        {/* end::Table */}

        {/* begin::Col */}
        {/* <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          <CardsWidget20
            className='h-md-50 mb-5 mb-xl-10'
            description='Active Projects'
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
          <CardsWidget7
            className='h-md-50 mb-5 mb-xl-10'
            description='Professionals'
            icon={false}
            stats={357}
            labelColor='dark'
            textColor='gray-300'
          />
        </div> */}
        {/* end::Col */}

        {/* begin::Col */}
        {/* <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          <CardsWidget17 className='h-md-50 mb-5 mb-xl-10' />
          <ListsWidget26 className='h-lg-50' />
        </div> */}
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xxl-6'>
          {/* <EngageWidget10 className='h-md-100' /> */}
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gx-5 gx-xl-10'>
        {/* begin::Col */}
        <div className='col-xxl-6 mb-5 mb-xl-10'>
          {/* <app-new-charts-widget8 cssclassName="h-xl-100" chartHeight="275px" [chartHeightNumber]="275"></app-new-charts-widget8> */}
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xxl-6 mb-5 mb-xl-10'>
          {/* <app-cards-widget18 cssclassName="h-xl-100" image="./assets/media/stock/600x600/img-65.jpg"></app-cards-widget18> */}
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      {/* <div className='row gy-5 gx-xl-8'>
        <div className='col-xxl-4'>
          <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
        </div>
        <div className='col-xl-8'>
          <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
        </div>
      </div> */}
      {/* end::Row */}

      {/* begin::Row */}
      {/* <div className='row gy-5 g-xl-8'>
        <div className='col-xl-4'>
          <ListsWidget2 className='card-xl-stretch mb-xl-8' />
        </div>
        <div className='col-xl-4'>
          <ListsWidget6 className='card-xl-stretch mb-xl-8' />
        </div>
        <div className='col-xl-4'>
          <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />
        </div>
      </div> */}
      {/* end::Row */}

      {/* <div className='row g-5 gx-xxl-8'>
        <div className='col-xxl-4'>
          <MixedWidget8
            className='card-xxl-stretch mb-xl-3'
            chartColor='success'
            chartHeight='150px'
          />
        </div>
        <div className='col-xxl-8'>
          <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' />
        </div>
      </div> */}
      <NewsDetailModal
        show={Boolean(currentNews)}
        handleClose={() => setCurrentNews(null)}
        news={currentNews}
        setNews={setCurrentNews}
        onSubmit={onDetailSubmit}
        onDelete={onDetailDelete} />
    </>
  )
}

const NewsWrapper = () => {
  return (
    <>
      <NewsPage />
    </>
  )
}

export default NewsWrapper
