/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import ImageInput from '../../components/ImageInput'
import { uploadFile } from '../../services/firebase'
// import {defaultCreateAppData, ICreateAppData} from '../'
// import {StepperComponent} from '../../../assets/ts/components'
// import {KTSVG} from '../../../helpers'
// import {Step1} from './steps/Step1'
// import {Step2} from './steps/Step2'
// import {Step3} from './steps/Step3'
// import {Step4} from './steps/Step4'
// import {Step5} from './steps/Step5'

const modalsRoot = document.getElementById('root-modals') || document.body

const SectionDetailModal = ({show, handleClose, section, setSection, onSubmit, onDelete, articles}) => {
  const stepperRef = useRef(null)
  const [selectedLanguage, setSelectedLanguage] = useState('fr')
  const [newImage, setNewImage] = useState(null)
  const [newIcon, setNewIcon] = useState(null)

  useEffect(() => {
    setNewImage(null)
    setNewIcon(null)
  }, [show])
  
  const updateData = (fieldsToUpdate) => {
    const updatedData = {...section, ...fieldsToUpdate}
    setSection(updatedData)
  }

  const onSave = async () => {
    const sec = {...section}
    if(newImage?.base64) {
      const uploadResponse = await uploadFile({ file: { name: newImage.name, type: newImage.type, size: newImage.size, base64: newImage.base64.split(',')[1]}})
      if(uploadResponse.url) {
        sec.cover_image = uploadResponse.url
      }
    }
    if(newIcon?.base64) {
      const uploadResponse = await uploadFile({ file: { name: newIcon.name, type: newIcon.type, size: newIcon.size, base64: newIcon.base64.split(',')[1]}})
      if(uploadResponse.url) {
        sec.icon = uploadResponse.url
      }
    }
    onSubmit(sec)
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={show}
      onHide={handleClose}
      // onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header'>
        <h2>{ section?.id ? 'Update Articles Section' : 'Create Articles Section' }</h2>
        {/* begin::Close */}
        {/* <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div> */}
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        {/*begin::Stepper */}
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_app_stepper'
        >


          {/*begin::Content */}
          <div className='flex-row-fluid py-lg-5 px-lg-15'>
            <div className='mb-5'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Language</span>
              </label>
              <div className='nav-group nav-group-outline nav-group-slim'>
                <label>
                  <input
                    type='radio'
                    className='btn-check'
                    name='type'
                    value='fr'
                    checked={selectedLanguage === 'fr'}
                    onChange={(e) => setSelectedLanguage('fr')}
                  />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary'>
                    FR
                  </span>
                </label>

                <label>
                  <input type='radio' className='btn-check' name='type' value='en' checked={selectedLanguage === 'en'} onChange={(e) => setSelectedLanguage('en')} />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                    EN
                  </span>
                </label>

                <label>
                  <input type='radio' className='btn-check' name='type' value='es' checked={selectedLanguage === 'es'} onChange={(e) => setSelectedLanguage('es')} />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                    ES
                  </span>
                </label>

                <label>
                  <input type='radio' className='btn-check' name='type' value='it' checked={selectedLanguage === 'it'} onChange={(e) => setSelectedLanguage('it')} />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                    IT
                  </span>
                </label>
              </div>
            </div>
            {/*begin::Form */}
            <form noValidate id='kt_modal_create_app_form'>
              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                  <span className='required'>Section title</span>
                  {/* <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Specify your unique section title'
                  ></i> */}
                </label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  name='section-title'
                  placeholder=''
                  value={section?.title[selectedLanguage] || ''}
                  onChange={(e) =>
                    updateData({
                      title: {
                        ...(section?.title || {}),
                        [selectedLanguage]: e.target.value,
                      },
                    })
                  }
                />
              </div>
              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                  <span>Image</span>
                </label>                
                <ImageInput onImageSelected={i => setNewImage(i)} imageUrl={newImage?.base64 ? newImage.base64 : section?.cover_image} />
              </div>
              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                  <span>Icon</span>
                </label>                
                <ImageInput onImageSelected={i => setNewIcon(i)} imageUrl={newIcon?.base64 ? newIcon.base64 : section?.icon} displayMode='contain' width='auto' height='auto' />
              </div>

              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                  <span>Articles</span>
                </label>                
                <div className='nav-group nav-group-outline nav-group-slim d-flex flex-column gap-3'>
                  { articles.map((article, index) =>
                    <label key={`article_${index}`}>
                      <input
                        type='checkbox'
                        className='btn-check'
                        name='type'
                        value={article.id}
                        checked={section?.articles?.includes(article.id)}
                        onChange={(e) => {
                          if(e.target.checked) {
                            updateData({
                              articles: [...(section?.articles || []), e.target.value],
                            })
                          } else {
                            updateData({
                              articles: (section?.articles || []).filter(a => a !== e.target.value),
                            })
                          }
                        }}
                      />
                      <span className='btn btn-sm btn-color-muted btn-active btn-active-primary'>
                        {article.title?.fr}
                      </span>
                    </label>
                  )}
                </div>
              </div>

              {/*begin::Actions */}
              <div className='d-flex flex-row-fluid justify-content-between align-items-center gap-2 gap-lg-3'>
                <div className='me-2'>
                  <button
                    type='button'
                    className='btn btn-lg'
                    data-kt-stepper-action='next'
                    onClick={handleClose}
                  >
                    { 'Close' }
                  </button>
                </div>                
                <div className='d-flex gap-3'>
                  { section?.id &&
                    <button
                      type='button'
                      className='btn btn-lg btn-danger'
                      data-kt-stepper-action='next'
                      onClick={onDelete}
                    >
                      { 'Delete' }
                    </button>
                  }
                  <button
                    type='button'
                    className='btn btn-lg btn-primary'
                    data-kt-stepper-action='next'
                    onClick={onSave}
                  >
                    { section?.id ? 'Update' : 'Create' }
                  </button>
                </div>
              </div>
              {/*end::Actions */}
            </form>
            {/*end::Form */}
          </div>
          {/*end::Content */}
        </div>
        {/* end::Stepper */}
      </div>
    </Modal>,
    modalsRoot
  )
}

export default SectionDetailModal
