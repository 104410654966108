import {useContext, useEffect, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import ToolbarCustom from '../../../_metronic/layout/components/toolbar/toolbars/ToolbarCustom'

import { ArticlesContext, ProductsContext } from '../../context'
import ProductSectionDetailModal from './ProductSectionDetail'

const ProductSectionsPage = () => {
  
  const { products, productsLoaded, fetchProducts, productSections, productSectionsLoaded, fetchProductSections, createProductSection, updateProductSection, deleteProductSection } = useContext(ProductsContext)

  const [currentProductSection, setCurrentProductSection] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if(!productSectionsLoaded) {
      fetchProductSections()
    }
  }, [productSectionsLoaded, fetchProductSections])

  useEffect(() => {
    if(!productsLoaded) {
      fetchProducts()
    }
  }, [productsLoaded, fetchProducts])

  const onCreateClick = () => {
    setCurrentProductSection({
      id: '',
      title: {},
      cover_image: ''
    })
  }

  const onEditClick = (productSection) => {
    setCurrentProductSection(productSection)
  }

  const onDetailSubmit = async (data) => {
    if(!data.id) {
      await createProductSection(data)
    } else {
      await updateProductSection(data.id, data)
    }
    setCurrentProductSection(null)
  }

  const onDetailDelete = async () => {
    await deleteProductSection(currentProductSection.id)
    setCurrentProductSection(null)
  }

  const itemWithId = (array, id) => {
    return array.find(i => i.id === id)
  }

  return (
    <>
      <ToolbarCustom pageTitle={'Product Sections'} primaryButton={productSectionsLoaded && { onClick: onCreateClick, title: 'Add' }} loading={!productSectionsLoaded} />
      {/* begin::Row */}
      <div className='row g-6 g-xl-9 mt-1'>
        { productSectionsLoaded && productSections.map((productSection, productSectionIndex) => 
          <div key={`productSection_${productSection.id}`} className='col-sm-6 col-xl-6'>
            <div className='card'>
              {/* begin::Body */}
              <div className='card-body pb-0'>
                {/* begin::Header */}
                <div className='d-flex align-items-center mb-5'>
                  {/* begin::User */}
                  <div className='d-flex align-items-center flex-grow-1'>
        
                    {/* begin::Info */}
                    <div className='d-flex flex-column'>
                      <span className='text-gray-800 text-dark fs-lg-4 fw-bold'>{productSection.title?.fr || 'Missing productSection title'}</span>
                    </div>
                    {/* end::Info */}
                  </div>
                  {/* end::User */}
        
                  {/* begin::Menu */}
                  <div className='my-0'>
                    <button
                      type='button'
                      className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                      onClick={() => onEditClick(productSection)}
                    >
                      <KTSVG path='/media/icons/duotune/general/gen055.svg' className='svg-icon-2' />
                    </button>
                  </div>
                  {/* end::Menu */}
                </div>
                {/* end::Header */}
        
                {/* begin::Post */}
                <div className='mb-3'>
                  {/* begin::Image */}
                  { productSection.cover_image && <div className='image-input fill-w image-input-outline image-input-empty image-input-outline-2' data-kt-image-input='true'>
                    <div className={`image-input-wrapper bgi-position-y-center bgi-position-x-center bgi-size-cover`} style={{backgroundImage: `url(${productSection.cover_image})`}}></div>
                  </div> }
                  {/* { productSection.cover_image && <div
                    className='bgi-no-repeat bgi-size-cover rounded mb-5 min-h-250px'
                    style={{
                      backgroundImage: `url('${productSection.cover_image}')`,
                    }}
                  ></div> } */}
                  {/* end::Image */}
        
                  {/* begin::Text */}
                  {/* <div className='text-gray-800 mb-5'>
                    { productSection.description?.fr}
                  </div> */}
                  {/* end::Text */}
                </div>
                <div className='d-flex flex-column mb-5'>
                  <span className='text-gray-800 text-dark fw-bold fs-5 mb-2'>Products</span>
                  { productSection.products?.map((productId, productIndex) =>
                    <div key={`product_${productId}`} className='d-flex flex-column'>
                      <span className='text-gray-800 text-dark text-hover-primary fs-lg-6 mb-2'>{`${itemWithId(products, productId)?.name?.fr}${!itemWithId(products, productId)?.active ? ' (inactive)' : ''}`}</span>
                    </div>
                  )}
                </div>
                {/* end::Post */}
              </div>
              {/* end::Body */}
            </div>
          </div>
        )}
        {/* begin::Col */}
        {/* <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          <CardsWidget20
            className='h-md-50 mb-5 mb-xl-10'
            description='Active Projects'
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
          <CardsWidget7
            className='h-md-50 mb-5 mb-xl-10'
            description='Professionals'
            icon={false}
            stats={357}
            labelColor='dark'
            textColor='gray-300'
          />
        </div> */}
        {/* end::Col */}

        {/* begin::Col */}
        {/* <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          <CardsWidget17 className='h-md-50 mb-5 mb-xl-10' />
          <ListsWidget26 className='h-lg-50' />
        </div> */}
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xxl-6'>
          {/* <EngageWidget10 className='h-md-100' /> */}
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gx-5 gx-xl-10'>
        {/* begin::Col */}
        <div className='col-xxl-6 mb-5 mb-xl-10'>
          {/* <app-new-charts-widget8 cssclassName="h-xl-100" chartHeight="275px" [chartHeightNumber]="275"></app-new-charts-widget8> */}
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xxl-6 mb-5 mb-xl-10'>
          {/* <app-cards-widget18 cssclassName="h-xl-100" image="./assets/media/stock/600x600/img-65.jpg"></app-cards-widget18> */}
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      {/* <div className='row gy-5 gx-xl-8'>
        <div className='col-xxl-4'>
          <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
        </div>
        <div className='col-xl-8'>
          <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
        </div>
      </div> */}
      {/* end::Row */}

      {/* begin::Row */}
      {/* <div className='row gy-5 g-xl-8'>
        <div className='col-xl-4'>
          <ListsWidget2 className='card-xl-stretch mb-xl-8' />
        </div>
        <div className='col-xl-4'>
          <ListsWidget6 className='card-xl-stretch mb-xl-8' />
        </div>
        <div className='col-xl-4'>
          <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />
        </div>
      </div> */}
      {/* end::Row */}

      {/* <div className='row g-5 gx-xxl-8'>
        <div className='col-xxl-4'>
          <MixedWidget8
            className='card-xxl-stretch mb-xl-3'
            chartColor='success'
            chartHeight='150px'
          />
        </div>
        <div className='col-xxl-8'>
          <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' />
        </div>
      </div> */}
      <ProductSectionDetailModal
        show={Boolean(currentProductSection)}
        handleClose={() => setCurrentProductSection(null)}
        section={currentProductSection}
        setSection={setCurrentProductSection}
        onSubmit={onDetailSubmit}
        onDelete={onDetailDelete}
        products={products} />
    </>
  )
}

const ProductSectionsWrapper = () => {
  return (
    <>
      <ProductSectionsPage />
    </>
  )
}

export default ProductSectionsWrapper
