import React, { useReducer } from 'react'
import { PRODUCTS, RESORTS, RESELLERS, COUNTRIES, REGIONS, DOMAINS, DURATIONS, PRODUCT_SECTIONS, RESORT_PARTNERS } from '../../constants/collections'
import { createDocument, deleteDocument, fetchCollection, updateDocument } from '../../services/firebase'
import { SET_PRODUCTS, SET_RESORTS, SET_RESELLERS, SET_COUNTRIES, SET_REGIONS, SET_DOMAINS, SET_DURATIONS, SET_PRODUCT_SECTIONS, SET_RESORT_PARTNERS } from '../types'
import productsReducer from './productsReducer'

const ProductsContext = React.createContext()

const ProductsState = ({ children }) => {

  const initialState = {
    products: [],
    productsLoaded: false,
    productSections: [],
    productSectionsLoaded: false,
    resellers: [],
    resellersLoaded: false,
    countries: [],
    countriesLoaded: false,
    regions: [],
    regionsLoaded: false,
    resorts: [],
    resortsLoaded: false,
    domains: [],
    domainsLoaded: false,
    durations: [],
    durationsLoaded: false,
    resortPartners: [],
    resortPartnersLoaded: false,
  }

  const [state, dispatch] = useReducer(productsReducer, initialState)

  const fetchProducts = async () => {
    const snapshot = await fetchCollection(PRODUCTS)
    const array = []
    snapshot.forEach(doc => {
      array.push({
        ...doc.data(),
        id: doc.id
      })
    })
    setProducts(array)
  }

  const createProduct = async (data) => {
    try {
      const snapshot = await createDocument(PRODUCTS, data)
      setProducts([
        { ...data, id: snapshot.id },
        ...state.products
      ])
      return { id: snapshot.id }
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const updateMultipleProducts = async (data) => {
    const promises = []
    for(let productId in data) {
      promises.push(updateDocument(`${PRODUCTS}/${productId}`, data[productId]))
    }
    await Promise.all(promises)
    let array = [...state.products]
    for(let productId in data) {
      let index = array.findIndex(s => s.id === productId)
      array[index] = {
        ...array[index],
        ...data[productId],
        id: productId
      }
    }
    setProducts(array)
  }

  const updateProduct = async (id, data) => {
    try {
      await updateDocument(`${PRODUCTS}/${id}`, data)
      let array = [...state.products]
      let index = array.findIndex(s => s.id === id)
      array[index] = {
        ...array[index],
        ...data,
        id
      }
      setProducts(array)
      return {}
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const deleteProduct = async (id) => {
    try {
      await deleteDocument(`${PRODUCTS}/${id}`)
      let array = [...state.products]
      let index = array.findIndex(s => s.id === id)
      array.splice(index, 1)
      setProducts(array)
      return {}
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const setProducts = (value) => {
    dispatch({
      type: SET_PRODUCTS,
      payload: value
    })
  }

  const fetchResorts = async () => {
    const snapshot = await fetchCollection(RESORTS)
    const array = []
    snapshot.forEach(doc => {
      array.push({
        ...doc.data(),
        id: doc.id
      })
    })
    setResorts(array)
  }

  const fetchDomains = async () => {
    const snapshot = await fetchCollection(DOMAINS)
    const array = []
    snapshot.forEach(doc => {
      array.push({
        ...doc.data(),
        id: doc.id
      })
    })
    setDomains(array)
  }

  const createDomain = async (data) => {
    try {
      const snapshot = await createDocument(DOMAINS, data)
      setDomains([
        ...state.domains,
        { ...data, id: snapshot.id }
      ])
      return { id: snapshot.id }
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const updateDomain = async (id, data) => {
    try {
      await updateDocument(`${DOMAINS}/${id}`, data)
      let array = [...state.domains]
      let index = array.findIndex(s => s.id === id)
      array[index] = {
        ...array[index],
        ...data,
        id
      }
      setDomains(array)
      return {}
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const deleteDomain = async (id) => {
    try {
      await deleteDocument(`${DOMAINS}/${id}`)
      let array = [...state.domains]
      let index = array.findIndex(s => s.id === id)
      array.splice(index, 1)
      setDomains(array)
      return {}
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const fetchDurations = async () => {
    const snapshot = await fetchCollection(DURATIONS)
    const array = []
    snapshot.forEach(doc => {
      array.push({
        ...doc.data(),
        id: doc.id
      })
    })
    setDurations(array)
  }

  const createDuration = async (data) => {
    try {
      const snapshot = await createDocument(DURATIONS, data)
      setDurations([
        ...state.durations,
        { ...data, id: snapshot.id }
      ])
      return { id: snapshot.id }
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const updateDuration = async (id, data) => {
    try {
      await updateDocument(`${DURATIONS}/${id}`, data)
      let array = [...state.durations]
      let index = array.findIndex(s => s.id === id)
      array[index] = {
        ...array[index],
        ...data,
        id
      }
      setDurations(array)
      return {}
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const deleteDuration = async (id) => {
    try {
      await deleteDocument(`${DURATIONS}/${id}`)
      let array = [...state.durations]
      let index = array.findIndex(s => s.id === id)
      array.splice(index, 1)
      setDurations(array)
      return {}
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const createResort = async (data) => {
    try {
      const snapshot = await createDocument(RESORTS, data)
      setResorts([
        ...state.resorts,
        { ...data, id: snapshot.id }
      ])
      return { id: snapshot.id }
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const updateResort = async (id, data) => {
    try {
      await updateDocument(`${RESORTS}/${id}`, data)
      let array = [...state.resorts]
      let index = array.findIndex(s => s.id === id)
      array[index] = {
        ...array[index],
        ...data,
        id
      }
      setResorts(array)
      return {}
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const deleteResort = async (id) => {
    try {
      await deleteDocument(`${RESORTS}/${id}`)
      let array = [...state.resorts]
      let index = array.findIndex(s => s.id === id)
      array.splice(index, 1)
      setResorts(array)
      return {}
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const setResorts = (value) => {
    dispatch({
      type: SET_RESORTS,
      payload: value
    })
  }

  const setDomains = (value) => {
    dispatch({
      type: SET_DOMAINS,
      payload: value
    })
  }

  const setDurations = (value) => {
    dispatch({
      type: SET_DURATIONS,
      payload: value
    })
  }

  const fetchResellers = async () => {
    const snapshot = await fetchCollection(RESELLERS)
    const array = []
    snapshot.forEach(doc => {
      array.push({
        ...doc.data(),
        id: doc.id
      })
    })
    setResellers(array)
  }

  const createReseller = async (data) => {
    try {
      const snapshot = await createDocument(RESELLERS, data)
      setResellers([
        ...state.resellers,
        { ...data, id: snapshot.id }
      ])
      return { id: snapshot.id }
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const updateReseller = async (id, data) => {
    try {
      await updateDocument(`${RESELLERS}/${id}`, data)
      let array = [...state.resellers]
      let index = array.findIndex(s => s.id === id)
      array[index] = {
        ...array[index],
        ...data,
        id
      }
      setResellers(array)
      return {}
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const deleteReseller = async (id) => {
    try {
      await deleteDocument(`${RESELLERS}/${id}`)
      let array = [...state.resellers]
      let index = array.findIndex(s => s.id === id)
      array.splice(index, 1)
      setResellers(array)
      return {}
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const setResellers = (value) => {
    dispatch({
      type: SET_RESELLERS,
      payload: value
    })
  } 

  const fetchResortPartners = async () => {
    const snapshot = await fetchCollection(RESORT_PARTNERS)
    const array = []
    snapshot.forEach(doc => {
      array.push({
        ...doc.data(),
        id: doc.id
      })
    })
    setResortPartners(array)
  }

  const createResortPartner = async (data) => {
    try {
      const snapshot = await createDocument(RESORT_PARTNERS, data)
      setResortPartners([
        ...state.resortPartners,
        { ...data, id: snapshot.id }
      ])
      return { id: snapshot.id }
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const updateResortPartner = async (id, data) => {
    try {
      await updateDocument(`${RESORT_PARTNERS}/${id}`, data)
      let array = [...state.resortPartners]
      let index = array.findIndex(s => s.id === id)
      array[index] = {
        ...array[index],
        ...data,
        id
      }
      setResortPartners(array)
      return {}
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const deleteResortPartner = async (id) => {
    try {
      await deleteDocument(`${RESORT_PARTNERS}/${id}`)
      let array = [...state.resortPartners]
      let index = array.findIndex(s => s.id === id)
      array.splice(index, 1)
      setResortPartners(array)
      return {}
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const setResortPartners = (value) => {
    dispatch({
      type: SET_RESORT_PARTNERS,
      payload: value
    })
  }

  const fetchCountries = async () => {
    const snapshot = await fetchCollection(COUNTRIES)
    const array = []
    snapshot.forEach(doc => {
      array.push({
        ...doc.data(),
        id: doc.id
      })
    })
    setCountries(array)
  }

  const createCountry = async (data) => {
    try {
      const snapshot = await createDocument(COUNTRIES, data)
      setCountries([
        ...state.countries,
        { ...data, id: snapshot.id }
      ])
      return { id: snapshot.id }
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const updateCountry = async (id, data) => {
    try {
      await updateDocument(`${COUNTRIES}/${id}`, data)
      let array = [...state.countries]
      let index = array.findIndex(s => s.id === id)
      array[index] = {
        ...array[index],
        ...data,
        id
      }
      setCountries(array)
      return {}
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const deleteCountry = async (id) => {
    try {
      await deleteDocument(`${COUNTRIES}/${id}`)
      let array = [...state.countries]
      let index = array.findIndex(s => s.id === id)
      array.splice(index, 1)
      setCountries(array)
      return {}
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const setCountries = (value) => {
    dispatch({
      type: SET_COUNTRIES,
      payload: value
    })
  } 

  const fetchRegions = async () => {
    const snapshot = await fetchCollection(REGIONS)
    const array = []
    snapshot.forEach(doc => {
      array.push({
        ...doc.data(),
        id: doc.id
      })
    })
    setRegions(array)
  }

  const createRegion = async (data) => {
    try {
      const snapshot = await createDocument(REGIONS, data)
      setRegions([
        ...state.regions,
        { ...data, id: snapshot.id }
      ])
      return { id: snapshot.id }
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const updateRegion = async (id, data) => {
    try {
      await updateDocument(`${REGIONS}/${id}`, data)
      let array = [...state.regions]
      let index = array.findIndex(s => s.id === id)
      array[index] = {
        ...array[index],
        ...data,
        id
      }
      setRegions(array)
      return {}
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const deleteRegion = async (id) => {
    try {
      await deleteDocument(`${REGIONS}/${id}`)
      let array = [...state.regions]
      let index = array.findIndex(s => s.id === id)
      array.splice(index, 1)
      setRegions(array)
      return {}
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const setRegions = (value) => {
    dispatch({
      type: SET_REGIONS,
      payload: value
    })
  }

  const fetchProductSections = async () => {
    const snapshot = await fetchCollection(PRODUCT_SECTIONS)
    const array = []
    snapshot.forEach(doc => {
      array.push({
        ...doc.data(),
        id: doc.id
      })
    })
    setProductSections(array)
  }

  const createProductSection = async (data) => {
    try {
      const snapshot = await createDocument(PRODUCT_SECTIONS, data)
      setProductSections([
        ...state.productSections,
        { ...data, id: snapshot.id }
      ])
      return { id: snapshot.id }
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const updateProductSection = async (id, data) => {
    try {
      await updateDocument(`${PRODUCT_SECTIONS}/${id}`, data)
      let array = [...state.productSections]
      let index = array.findIndex(s => s.id === id)
      array[index] = {
        ...array[index],
        ...data,
        id
      }
      setProductSections(array)
      return {}
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const deleteProductSection = async (id) => {
    try {
      await deleteDocument(`${PRODUCT_SECTIONS}/${id}`)
      let array = [...state.productSections]
      let index = array.findIndex(s => s.id === id)
      array.splice(index, 1)
      setProductSections(array)
      return {}
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const setProductSections = (value) => {
    dispatch({
      type: SET_PRODUCT_SECTIONS,
      payload: value
    })
  }


  return (
    <ProductsContext.Provider value={{
      products: state.products,
      productsLoaded: state.productsLoaded,
      resellers: state.resellers,
      resellersLoaded: state.resellersLoaded,
      countries: state.countries,
      countriesLoaded: state.countriesLoaded,
      resorts: state.resorts,
      resortsLoaded: state.resortsLoaded,
      domains: state.domains,
      domainsLoaded: state.domainsLoaded,
      durations: state.durations,
      durationsLoaded: state.durationsLoaded,
      regions: state.regions,
      regionsLoaded: state.regionsLoaded,
      seasons: state.seasons,
      fields: state.fields,
      productSections: state.productSections,
      productSectionsLoaded: state.productSectionsLoaded,
      resortPartners: state.resortPartners,
      resortPartnersLoaded: state.resortPartnersLoaded,
      fetchProducts,
      createProduct,
      updateProduct,
      updateMultipleProducts,
      deleteProduct,
      fetchResorts,
      fetchDomains,
      createDomain,
      updateDomain,
      deleteDomain,
      fetchDurations,
      createDuration,
      updateDuration,
      deleteDuration,
      createResort,
      updateResort,
      deleteResort,
      fetchResellers,
      createReseller,
      updateReseller,
      deleteReseller,
      fetchCountries,
      createCountry,
      updateCountry,
      deleteCountry,
      fetchRegions,
      createRegion,
      updateRegion,
      deleteRegion,
      fetchProductSections,
      createProductSection,
      updateProductSection,
      deleteProductSection,
      fetchResortPartners,
      createResortPartner,
      updateResortPartner,
      deleteResortPartner,
    }}>
      { children }
    </ProductsContext.Provider>
  )
}

export { ProductsContext }

export default ProductsState