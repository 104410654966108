import {useContext, useEffect, useState} from 'react'
import moment from 'moment'
import {KTSVG} from '../../../_metronic/helpers'
import ToolbarCustom from '../../../_metronic/layout/components/toolbar/toolbars/ToolbarCustom'

import { ProductsContext } from '../../context'
import PartnerDetailModal from './PartnerDetailModal'

const PartnersPage = () => {
  
  const { resortPartners, resortPartnersLoaded, fetchResortPartners, createResortPartner, updateResortPartner, deleteResortPartner, resortsLoaded, fetchResorts } = useContext(ProductsContext)

  const [currentResortPartner, setCurrentResortPartner] = useState(null)
  const [sortBy, setSortBy] = useState('name')
  const [sortOrder, setSortOrder] = useState('asc')
  const [filteredResortPartners, setFilteredResortPartners] = useState([])

  const onSortClick = (property) => {
    if(sortBy !== property) {
      setSortBy(property)
      setSortOrder('asc')
    } else {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    }
  }

  useEffect(() => {
    if(!resortsLoaded) {
      fetchResorts()
    }
  }, [resortsLoaded, fetchResorts])

  useEffect(() => {
    if(!resortPartnersLoaded) {
      return
    }
    let filtered = [...resortPartners]
    if(sortBy === 'company_name') {
      filtered = filtered.sort((a, b) => {
        if(a.company_name?.toLowerCase() < b.company_name?.toLowerCase()) {
          return sortOrder === 'asc' ? -1 : 1
        }
        if(a.company_name?.toLowerCase() > b.company_name?.toLowerCase()) {
          return sortOrder === 'asc' ? 1 : -1
        }
        return 0
      })
    }
    if(sortBy === 'updated') {
      filtered = filtered.sort((a, b) => {
        console.log(`Sort ${a.meta?.updated} ${b.meta?.updated}`)
        if(a.meta?.updated < b.meta?.updated) {
          return sortOrder === 'asc' ? -1 : 1
        }
        if(a.meta?.updated > b.meta?.updated) {
          return sortOrder === 'asc' ? 1 : -1
        }
        return 0
      })
    }
    if(sortBy === 'resorts') {
      filtered = filtered.sort((a, b) => {
        if((a.resorts || []).length < (b.resorts || []).length) {
          return sortOrder === 'asc' ? -1 : 1
        }
        if((a.resorts || []).length > (b.resorts || []).length) {
          return sortOrder === 'asc' ? 1 : -1
        }
        return 0
      })
    }
    setFilteredResortPartners(filtered)
  }, [resortPartnersLoaded, resortPartners, sortBy, sortOrder])


  useEffect(() => {
    if(!resortPartnersLoaded) {
      fetchResortPartners()
    }
  }, [resortPartnersLoaded, fetchResortPartners])

  const onCreateClick = () => {
    setCurrentResortPartner({
      id: '',
      name: '',
      country: ''
    })
  }

  const onEditClick = (resortPartner) => {
    setCurrentResortPartner(resortPartner)
  }

  const onDetailSubmit = async (data) => {
    let response
    // const data = {...currentResortPartner}
    if(!data.id) {
      response = await createResortPartner(data)
    } else {
      response = await updateResortPartner(data.id, data)
    }
    setCurrentResortPartner(null)
  }

  const onDetailDelete = async () => {
    if(!window.confirm('Are you sure you want to delete this partner?')) {
      return
    }
    await deleteResortPartner(currentResortPartner.id)
    setCurrentResortPartner(null)
  }

  const itemWithId = (array, id) => {
    return array.find(i => i.id === id)
  }

  const limitCharacters = (text, limit) => {
    if(!text) return ''
    if(text.length > limit) {
      return text.substring(0, limit) + '...'
    }
    return text
  }

  return (
    <>
      <ToolbarCustom pageTitle={'Partners'} primaryButton={resortPartnersLoaded && { onClick: onCreateClick, title: 'Add' }} loading={!resortPartnersLoaded} />

      {/* List of resortPartners - sortable table with properties: company_name, meta?.updated */}
      <div className='row g-6 g-xl-9 mt-1'>
        {/* begin::Table */}
        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
          {/* begin::Table head */}
          <thead>
            <tr className='fw-bold text-muted'>
              <th className='w-15px'>
              </th>
              <th onClick={() => onSortClick('name')} className='min-w-100px'>{`Name${sortBy === 'name' ? sortOrder === 'desc' ? '↑' : '↓' : ''}`}</th>
              <th onClick={() => onSortClick('updated')} className='min-w-80px text-center'>{`Updated${sortBy === 'updated' ? sortOrder === 'desc' ? '↑' : '↓' : ''}`}</th>
              {/* <th className='min-w-100px text-end'></th> */}
            </tr>
          </thead>
          {/* end::Table head */}
          {/* begin::Table body */}
          <tbody>
          { resortPartnersLoaded && filteredResortPartners.map((resortPartner) => 
            <tr key={`resortPartner_${resortPartner.id}`}>
              <td>
              </td>
              <td>
                <div className='d-flex align-items-center'>
                  <div className='d-flex justify-content-start flex-column'>
                    <div className='text-dark fw-bold text-hover-primary fs-6 cursor-pointer' onClick={() => onEditClick(resortPartner)}>
                      { resortPartner.name?.fr || 'N/A' }
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <span className='text-muted fw-semibold text-muted d-block fs-7 text-center'>
                    { resortPartner.meta?.updated ? moment(resortPartner.meta?.updated).format('DD/MM/YYYY HH:mm') : 'N/A' }
                </span>
              </td>
            </tr>
          )}
          </tbody>
          {/* end::Table body */}
        </table>
        {/* end::Table */}
      </div>


      <PartnerDetailModal
        show={Boolean(currentResortPartner)}
        handleClose={() => setCurrentResortPartner(null)}
        resortPartner={currentResortPartner}
        setResortPartner={setCurrentResortPartner}
        onSubmit={onDetailSubmit}
        onDelete={onDetailDelete} />
    </>
  )
}

export default PartnersPage
