import { SET_REGIONS, SET_COUNTRIES, SET_PRODUCTS, SET_RESELLERS, SET_RESORTS, SET_DOMAINS, SET_DURATIONS, SET_PRODUCT_SECTIONS, SET_RESORT_PARTNERS } from '../types';

const articlesReducer = (state, action) => {
  switch(action.type) {
    case SET_PRODUCTS:
      return {
        ...state,
        products: action.payload,
        productsLoaded: true
      }
    case SET_PRODUCT_SECTIONS:
      return {
        ...state,
        productSections: action.payload,
        productSectionsLoaded: true
      }
    case SET_RESORTS:
      return {
        ...state,
        resorts: action.payload,
        resortsLoaded: true
      }
    case SET_DOMAINS:
      return {
        ...state,
        domains: action.payload,
        domainsLoaded: true
      }
    case SET_DURATIONS:
      return {
        ...state,
        durations: action.payload,
        durationsLoaded: true
      }
    case SET_RESELLERS:
      return {
        ...state,
        resellers: action.payload,
        resellersLoaded: true
      }
    case SET_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
        countriesLoaded: true
      }
    case SET_REGIONS:
      return {
        ...state,
        regions: action.payload,
        regionsLoaded: true
      }
    case SET_RESORT_PARTNERS:
      return {
        ...state,
        resortPartners: action.payload,
        resortPartnersLoaded: true
      }
    default:
      return state;
  }
}

export default articlesReducer