import {useContext, useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import ToolbarCustom from '../../../_metronic/layout/components/toolbar/toolbars/ToolbarCustom'

import { ContentContext } from '../../context'
import FaqDetailModal from './FaqDetail'
import moment from 'moment'

const FaqPage = () => {
  
  const { faqs, faqsLoaded, fetchFaqs, createFaq, updateFaq, deleteFaq } = useContext(ContentContext)

  const [currentFaq, setCurrentFaq] = useState(null)
  const [sortBy, setSortBy] = useState('name')
  const [sortOrder, setSortOrder] = useState('asc')
  const [filteredFaqs, setFilteredFaqs] = useState([])
  const [search, setSearch] = useState('')

  useEffect(() => {
    if(!faqsLoaded) {
      fetchFaqs()
    }
  }, [faqsLoaded, fetchFaqs])

  const onCreateClick = () => {
    setCurrentFaq({
      id: '',
      question: {},
      answer: {},
      published: false,
    })
  }

  const onEditClick = (faq) => {
    setCurrentFaq(faq)
  }

  const onDetailSubmit = async (data) => {
    if(!data.id) {
      await createFaq(data)
    } else {
      await updateFaq(data.id, data)
    }
    setCurrentFaq(null)
  }

  const onDetailDelete = async () => {
    await deleteFaq(currentFaq.id)
    setCurrentFaq(null)
  }

  const onSortClick = (property) => {
    if(sortBy !== property) {
      setSortBy(property)
      setSortOrder('asc')
    } else {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    }
  }

  useEffect(() => {
    let r = [...faqs]
    if(sortBy === 'question') {
      r.sort((a, b) => {
        if(a.question.fr < b.question.fr) return sortOrder === 'asc' ? -1 : 1
        if(a.question.fr > b.question.fr) return sortOrder === 'asc' ? 1 : -1
        return 0
      })
    } else if(sortBy === 'updated') {
      r.sort((a, b) => {
        if(a.meta.updated < b.meta.updated) return sortOrder === 'asc' ? -1 : 1
        if(a.meta.updated > b.meta.updated) return sortOrder === 'asc' ? 1 : -1
        return 0
      })
    } else if(sortBy === 'status') {
      r.sort((a, b) => {
        if(a.published < b.published) return sortOrder === 'asc' ? -1 : 1
        if(a.published > b.published) return sortOrder === 'asc' ? 1 : -1
        return 0
      })
    }
    if(search) {
      let searchComponents = search.split(/\s/g).map(s => s.trim()).filter(s => s)
      r = r.filter(faq => {
        let foundCount = 0
        for(let searchComponent of searchComponents) {
          if(faq.question.fr.toLowerCase().includes(searchComponent.toLowerCase())) {
            foundCount++
          }
        }
        return foundCount === searchComponents.length
      })
    }
    setFilteredFaqs(r)
  }, [faqs, sortOrder, sortBy, search])

  const togglePublish = async (faq) => {
    await updateFaq(faq.id, { ...faq, published: !faq.published })
  }

  return (
    <>
      <ToolbarCustom
        pageTitle={'Home page FAQs'}
        primaryButton={faqsLoaded && { onClick: onCreateClick, title: 'Add' }}
        filterElements={[
        <div
          data-kt-search-element='form'
          className='w-100 position-relative'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen021.svg'
            className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 translate-middle-y ms-0'
          />
          <input
            type='text'
            className='form-control form-control-flush ps-10'
            name='search'
            value={search}
            placeholder='Search...'
            data-kt-search-element='input'
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>]}
        loading={!faqsLoaded} />
      <div className='row g-6 g-xl-9 mt-1'>
        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
          <thead>
            <tr className='fw-bold text-muted'>
              <th className='w-15px'>
              </th>
              <th onClick={() => onSortClick('question')} className='min-w-150px'>{`Question${sortBy === 'question' ? sortOrder === 'desc' ? ' ↑' : ' ↓' : ''}`}</th>
              <th onClick={() => onSortClick('updated')} className='min-w-150px'>{`Updated${sortBy === 'updated' ? sortOrder === 'desc' ? ' ↑' : ' ↓' : ''}`}</th>
              <th onClick={() => onSortClick('status')} className='min-w-140px'>{`Status${sortBy === 'status' ? sortOrder === 'desc' ? ' ↑' : ' ↓' : ''}`}</th>
              <th className='min-w-100px text-end'>Actions</th>
            </tr>
          </thead>
          <tbody>
          { faqsLoaded && filteredFaqs.map((faq) => 
            <tr key={`faq_${faq.id}`}>
              <td>
              </td>
              <td>
                <div className='d-flex align-items-center'>
                  <div className='d-flex justify-content-start flex-column'>
                    <div className={`${faq.published ? 'text-primary' : 'text-dark'} fw-bold fs-6`}>
                      { faq.question.fr }
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div className='d-flex align-items-center'>
                  <div className='d-flex justify-content-start flex-column'>
                    <div className='text-dark fw-semibold d-block fs-7'>
                      { moment(faq.meta.updated).format("DD/MM/YYYY HH:mm") }
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div className='text-dark fw-semibold d-block fs-7'>
                  { faq.published ? 'Published' : 'Draft' }
                </div>                
              </td>
              <td>
                <div className='d-flex justify-content-end flex-shrink-0'>
                  <button
                  onClick={() => togglePublish(faq)}
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                  >
                    <KTSVG path={faq.published ? '/media/icons/duotune/general/gen040.svg' : '/media/icons/duotune/general/gen043.svg'} className='svg-icon-3' />
                  </button>

                  <button
                  onClick={() => onEditClick(faq)}
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                  >
                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                  </button>
                </div>
              </td>
            </tr>
          )}
          </tbody>
        </table>
      </div>

      <FaqDetailModal
        show={Boolean(currentFaq)}
        handleClose={() => setCurrentFaq(null)}
        faq={currentFaq}
        setFaq={setCurrentFaq}
        onSubmit={onDetailSubmit}
        onDelete={onDetailDelete} />
    </>
  )
}

const FaqWrapper = () => {
  return (
    <>
      <FaqPage />
    </>
  )
}

export default FaqWrapper
