import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import { useIntl } from 'react-intl'
import DashboardPage from '../pages/Dashboard/DashboardWrapper'
import ArticlesPage from '../pages/Articles/Articles'
import ProductsPage from '../pages/Products/Products'
// import UnderConstruction from '../modules/errors/components/UnderConstruction'
import ResortsPage from '../pages/Resorts/Resorts'
import ResellersPage from '../pages/Resellers/Resellers'
import CountriesPage from '../pages/Countries/Countries'
import RegionsPage from '../pages/Regions/Regions'
import CustomersPage from '../pages/Customers/Customers'
import ContactPage from '../pages/Contact/Contact'
import CommercialContactPage from '../pages/Contact/CommercialContact'
import PurchasesPage from '../pages/Purchases/Purchases'
import DomainsPage from '../pages/Domains/Domains'
import DurationsPage from '../pages/Durations/Durations'
import StatisticsPage from '../pages/Statistics/Statistics'
import NewsPage from '../pages/News/News'
import InfoBlocksPage from '../pages/InfoBlocks/InfoBlocks'
import FaqsPage from '../pages/Faqs/Faqs'
import ArticlesHubPage from '../pages/Articles/ArticlesHub'
import ArticleSectionsPage from '../pages/ArticleSections/ArticleSections'
import ProductsHubPage from '../pages/Products/ProductsHub'
import ProductSectionsPage from '../pages/Products/ProductSections'
import ContactFormsPage from '../pages/Contact/ContactForms'
import PurchaseOrdersPage from '../pages/PurchaseOrders/PurchaseOrders'
import PartnersPage from '../pages/Partners/Partners'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardPage />} />

        <Route path='articles' element={<ArticlesPage />} />
        <Route path='article-hub' element={<ArticlesHubPage />} />
        <Route path='article-sections' element={<ArticleSectionsPage />} />

        <Route path='products' element={<ProductsPage />} />
        <Route path='product-hub' element={<ProductsHubPage />} />
        <Route path='product-sections' element={<ProductSectionsPage />} />

        <Route path='purchases' element={<PurchasesPage />} />
        <Route path='users' element={<CustomersPage />} />
        <Route path='purchase-orders' element={<PurchaseOrdersPage />} />
        
        <Route path='domains' element={<DomainsPage />} />
        <Route path='durations' element={<DurationsPage />} />
        <Route path='resorts' element={<ResortsPage />} />
        <Route path='countries' element={<CountriesPage />} />
        <Route path='regions' element={<RegionsPage />} />
        <Route path='network' element={<ResellersPage />} />
        <Route path='partners' element={<PartnersPage />} />

        <Route path='statistics' element={<StatisticsPage />} />
        
        <Route path='news' element={<NewsPage />} />
        <Route path='info-blocks' element={<InfoBlocksPage />} />
        <Route path='faqs' element={<FaqsPage />} />
        
        <Route path='contact' element={<ContactPage />} />
        <Route path='commercial-contact' element={<CommercialContactPage />} />
        <Route path='requests' element={<ContactFormsPage />} />
        <Route path='forms' element={<ContactFormsPage />} />
        {/* <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} /> */}
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
