import {useContext, useEffect, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import ToolbarCustom from '../../../_metronic/layout/components/toolbar/toolbars/ToolbarCustom'

import { ProductsContext } from '../../context'
import ProductDetailModal from './ProductDetailModal'

const ProductsPage = () => {
  
  const {
    products,
    productsLoaded,
    fetchProducts,
    createProduct,
    updateProduct,
    deleteProduct,
    resortsLoaded,
    fetchResorts,
    domainsLoaded,
    fetchDomains,
    durationsLoaded,
    fetchDurations,
    resellersLoaded,
    fetchResellers
  } = useContext(ProductsContext)

  const [currentProduct, setCurrentProduct] = useState(null)
  const [filter, setFilter] = useState({
    season: 'all'
  })
  const [filteredProducts, setFilteredProducts] = useState([])

  useEffect(() => {
    if(!productsLoaded) {
      fetchProducts()
    }
  }, [productsLoaded, fetchProducts])

  useEffect(() => {
    if(!resortsLoaded) {
      fetchResorts()
    }
  }, [resortsLoaded, fetchResorts])

  useEffect(() => {
    if(!domainsLoaded) {
      fetchDomains()
    }
  }, [domainsLoaded, fetchDomains])

  useEffect(() => {
    if(!durationsLoaded) {
      fetchDurations()
    }
  }, [durationsLoaded, fetchDurations])

  useEffect(() => {
    if(!resellersLoaded) {
      fetchResellers()
    }
  }, [resellersLoaded, fetchResellers])

  useEffect(() => {
    setFilteredProducts([...products].filter(p => {
      return filter.season === 'all' || p.season === filter.season
    }).sort((a, b) => (b.meta?.updated || 0) - (a.meta?.updated || 0)))
  }, [products, filter])

  const onCreateClick = () => {
    setCurrentProduct({
      id: '',
      name: {},
      guarantees: [],
      regions: [],
      resorts: [],
      resellers: [],
      default_price: 0
    })
  }

  const onEditClick = (product) => {
    setCurrentProduct(product)
  }

  const onDetailSubmit = async (data) => {
    // const data = {...currentProduct}
    if(!data.id) {
      await createProduct(data)
    } else {
      await updateProduct(data.id, data)
    }
    setCurrentProduct(null)
  }

  const onDetailDelete = async () => {
    await deleteProduct(currentProduct.id)
    setCurrentProduct(null)
  }

  const labelForSeason = (season) => {
    switch(season) {
      case 'summer':
        return 'Summer'
      case 'winter':
        return 'Winter'
      case 'universal':
        return 'Universal'
      default:
        return ''
    }
  }

  const onDuplicateClick = async (product) => {
    const duplicate = {...product}
    for(let lang in duplicate.name) {
      duplicate.name[lang] = `Copy of ${duplicate.name[lang]}`
    }
    duplicate.active = false
    delete duplicate.id
    await createProduct(duplicate)
  }

  return (
    <>
      <ToolbarCustom
        pageTitle={'Products'}
        primaryButton={productsLoaded && { onClick: onCreateClick, title: 'Add' }}
        loading={!productsLoaded}
        // filterElements={[
        //   <div key={`filter_element_section`}>
        //     <label htmlFor='filter_section'>Season</label>
        //     <div className='col-lg-8 fv-row'>
        //       <select
        //         name='filter_section'
        //         className='form-select form-select-sm'
        //         value={filter.season}
        //         onChange={e => setFilter({
        //           ...filter,
        //           season: e.target.value
        //         })}
        //       >
        //         <option value='all'>All</option>
        //         { seasons.map((season, si) =>
        //           <option key={`filter_section_option_${si}`} value={season}>
        //           { labelForSeason(season) }
        //           </option>
        //         )}
        //       </select>
        //     </div>
        //   </div>
        // ]}
        />
      {/* begin::Row */}
      <div className='row g-6 g-xl-9 mt-1'>
        { productsLoaded && filteredProducts.map((product, productIndex) => 
          <div key={`product_${product.id}`} className='col-sm-12 col-xl-12'>
            <div className='card'>
              {/* begin::Body */}
              <div className='card-body pb-0'>
                {/* begin::Header */}
                <div className='d-flex align-items-center mb-1'>
                  {/* begin::User */}
                  <div className='d-flex align-items-center flex-grow-1'>
        
                    {/* begin::Info */}
                    <div className='d-flex flex-column'>
                      <span className='text-gray-800 text-hover-primary fs-lg-4 fw-bold'>{product.name?.fr || 'Missing product name'}</span>
                    </div>                    
                    {/* end::Info */}
                  </div>
                  <div className='d-flex align-items-end flex-grow-1'>
                    
                  </div>
                  {/* end::User */}
        
                  {/* begin::Menu */}
                  <div className='d-flex flex-row align-items-center'>
                    <div className='d-flex flex-column'>
                      <span className='text-gray-800 text-hover-primary fs-lg-4 fw-bold'>{product.active ? 'Active' : 'Disabled' }&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </div>
                    <button
                      type='button'
                      className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                      onClick={() => onEditClick(product)}
                    >
                      Edit
                      {/* <KTSVG path='/media/icons/duotune/general/gen055.svg' className='svg-icon-2' /> */}
                    </button>
                    <button
                      type='button'
                      className='btn btn-sm btn-color-primary btn-active-light-primary'
                      onClick={() => onDuplicateClick(product)}
                    >
                      Duplicate
                    </button>
                  </div>
                  {/* end::Menu */}
                </div>
                {/* end::Header */}
        
                {/* begin::Post */}
                <div className='mb-5'>
                  {/* begin::Image */}
                  {/* { product.cover_image && <div
                    className='bgi-no-repeat bgi-size-cover rounded mb-5 min-h-250px'
                    style={{
                      backgroundImage: `url('${product.cover_image}')`,
                    }}
                  ></div> } */}
                  {/* end::Image */}
        
                  {/* begin::Text */}
                  <div className='text-gray-800 mb-5'>
                    { product.description?.en?.substring(0, 80) + (product.description?.en?.length > 80 && "...") || ''}
                  </div>
                  {/* end::Text */}
                </div>
                {/* end::Post */}
              </div>
              {/* end::Body */}
            </div>
          </div>
        )}
        {/* begin::Col */}
        {/* <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          <CardsWidget20
            className='h-md-50 mb-5 mb-xl-10'
            description='Active Projects'
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
          <CardsWidget7
            className='h-md-50 mb-5 mb-xl-10'
            description='Professionals'
            icon={false}
            stats={357}
            labelColor='dark'
            textColor='gray-300'
          />
        </div> */}
        {/* end::Col */}

        {/* begin::Col */}
        {/* <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          <CardsWidget17 className='h-md-50 mb-5 mb-xl-10' />
          <ListsWidget26 className='h-lg-50' />
        </div> */}
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xxl-6'>
          {/* <EngageWidget10 className='h-md-100' /> */}
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gx-5 gx-xl-10'>
        {/* begin::Col */}
        <div className='col-xxl-6 mb-5 mb-xl-10'>
          {/* <app-new-charts-widget8 cssclassName="h-xl-100" chartHeight="275px" [chartHeightNumber]="275"></app-new-charts-widget8> */}
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xxl-6 mb-5 mb-xl-10'>
          {/* <app-cards-widget18 cssclassName="h-xl-100" image="./assets/media/stock/600x600/img-65.jpg"></app-cards-widget18> */}
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      {/* <div className='row gy-5 gx-xl-8'>
        <div className='col-xxl-4'>
          <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
        </div>
        <div className='col-xl-8'>
          <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
        </div>
      </div> */}
      {/* end::Row */}

      {/* begin::Row */}
      {/* <div className='row gy-5 g-xl-8'>
        <div className='col-xl-4'>
          <ListsWidget2 className='card-xl-stretch mb-xl-8' />
        </div>
        <div className='col-xl-4'>
          <ListsWidget6 className='card-xl-stretch mb-xl-8' />
        </div>
        <div className='col-xl-4'>
          <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />
        </div>
      </div> */}
      {/* end::Row */}

      {/* <div className='row g-5 gx-xxl-8'>
        <div className='col-xxl-4'>
          <MixedWidget8
            className='card-xxl-stretch mb-xl-3'
            chartColor='success'
            chartHeight='150px'
          />
        </div>
        <div className='col-xxl-8'>
          <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' />
        </div>
      </div> */}
      <ProductDetailModal
        show={Boolean(currentProduct)}
        handleClose={() => setCurrentProduct(null)}
        product={currentProduct}
        setProduct={setCurrentProduct}
        onSubmit={onDetailSubmit}
        onDelete={onDetailDelete} />
    </>
  )
}

const ProductsWrapper = () => {
  return (
    <>
      <ProductsPage />
    </>
  )
}

export default ProductsWrapper
