/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useRef, useContext, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Button, Modal} from 'react-bootstrap'
import { EditorState } from "draft-js"

import { createEditorStateFromRaw, serialiseEditorStateToRaw } from "draftail"
import Editor, { customDecorators, fromHTML, toHTML } from '../../components/Editor'
import ImageInput from '../../components/ImageInput'
import { uploadFile } from '../../services/firebase'

const modalsRoot = document.getElementById('root-modals') || document.body

const ArticleDetailModal = ({show, handleClose, article, setArticle, onSubmit, onDelete}) => {

  const stepperRef = useRef(null)
  const [selectedLanguage, setSelectedLanguage] = useState('fr')

  const [newCoverImage, setNewCoverImage] = useState(null)
  const [keywords, setKeywords] = useState([])

  const [editorStates, setEditorStates] = useState({
    en: EditorState.createEmpty(),
    fr: EditorState.createEmpty(),
    it: EditorState.createEmpty(),
    es: EditorState.createEmpty()
  })

  useEffect(() => {
    setNewCoverImage(null)
    if(!article) {
      return
    }
    setEditorStates({
      en: createEditorStateFromRaw(fromHTML(article.text?.en || ''), customDecorators),
      fr: createEditorStateFromRaw(fromHTML(article.text?.fr || ''), customDecorators),
      it: createEditorStateFromRaw(fromHTML(article.text?.it || ''), customDecorators),
      es: createEditorStateFromRaw(fromHTML(article.text?.es || ''), customDecorators)
    })
    setKeywords({
      en: (article.keywords?.en || []).join(', ') || '',
      fr: (article.keywords?.fr || []).join(', ') || '',
      it: (article.keywords?.it || []).join(', ') || '',
      es: (article.keywords?.es || []).join(', ') || '',
    })
  }, [article])


  const handleEditorChange = (content) => {
    setEditorStates({
      ...editorStates,
      [selectedLanguage]: content
    })
  }

  const updateData = (fieldsToUpdate) => {
    const updatedData = {...article, ...fieldsToUpdate}
    setArticle({...updatedData})
  }

  const onClose = () => {
    handleClose()
  }

  const onSave = async () => {
    const art = {...article}
    if(newCoverImage?.base64) {
      const uploadResponse = await uploadFile({ file: { name: newCoverImage.name, type: newCoverImage.type, size: newCoverImage.size, base64: newCoverImage.base64.split(',')[1]}})
      if(uploadResponse.url) {
        art.cover_image = uploadResponse.url
      }
    }
    art.text = {
      en: toHTML(serialiseEditorStateToRaw(editorStates.en)),
      fr: toHTML(serialiseEditorStateToRaw(editorStates.fr)),
      it: toHTML(serialiseEditorStateToRaw(editorStates.it)),
      es: toHTML(serialiseEditorStateToRaw(editorStates.es)),
    }
    art.keywords = {
      en: keywords.en.split(',').map(k => k.trim()),
      fr: keywords.fr.split(',').map(k => k.trim()),
      it: keywords.it.split(',').map(k => k.trim()),
      es: keywords.es.split(',').map(k => k.trim()),
    }
    onSubmit(art)
  }
  
  const handleCopyContent = () => {
    setEditorStates({
      ...editorStates,
      [selectedLanguage]: editorStates["fr"]
    })
  }

  const handleExportContent = () => {
    const html = toHTML(serialiseEditorStateToRaw(editorStates[selectedLanguage]))
    const a = document.createElement('a')
    a.download = `article-${article.title[selectedLanguage]}-${selectedLanguage}.html`
    a.href = 'data:text/html;charset=utf-8,' + encodeURIComponent(html)
    a.click()
  }

  const handleImportContent = () => {
    const input = document.createElement('input')
    input.type = "file"
    input.accept = "text/html"
    input.onchange = e => {
      const file = e.target.files[0];
      if(!file) return
      const reader = new FileReader()
      reader.readAsText(file, 'UTF-8')
      reader.onload = readerEvent => {
        const content = readerEvent.target.result
        setEditorStates({
          ...editorStates,
          [selectedLanguage]: createEditorStateFromRaw(fromHTML(content), customDecorators)
        })
      }
    }
    input.click()
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={show}
      onHide={onClose}
      // onEntered={loadStepper}
      backdrop={true}
      enforceFocus={false}
    >
      <div className='modal-header'>
        <h2>{ article?.id ? 'Update Article' : 'Create Article' }</h2>
        {/* begin::Close */}
        {/* <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div> */}
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        {/*begin::Stepper */}
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_app_stepper'
        >


          {/*begin::Content */}
          <div className='flex-row-fluid py-lg-5 px-lg-15'>
            <div className='mb-5'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Language</span>
              </label>
              <div className='nav-group nav-group-outline nav-group-slim'>
                <label>
                  <input
                    type='radio'
                    className='btn-check'
                    name='type'
                    value='fr'
                    checked={selectedLanguage === 'fr'}
                    onChange={(e) => setSelectedLanguage('fr')}
                  />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary'>
                    FR
                  </span>
                </label>

                <label>
                  <input type='radio' className='btn-check' name='type' value='en' checked={selectedLanguage === 'en'} onChange={(e) => setSelectedLanguage('en')} />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                    EN
                  </span>
                </label>

                <label>
                  <input type='radio' className='btn-check' name='type' value='es' checked={selectedLanguage === 'es'} onChange={(e) => setSelectedLanguage('es')} />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                    ES
                  </span>
                </label>

                <label>
                  <input type='radio' className='btn-check' name='type' value='it' checked={selectedLanguage === 'it'} onChange={(e) => setSelectedLanguage('it')} />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                    IT
                  </span>
                </label>
              </div>
            </div>
            {/* <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Section</label>

              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg'
                  value={article?.section || ''}
                  onChange={e => updateData({
                    section: e.target.value
                  })}
                >
                  <option disabled value=''>Select a section..</option>
                  { sections.map((section) =>
                    <option key={`section_option_${section.id}`} value={section.id}>
                     { section.title?.fr}
                    </option>
                  )}
                </select>
              </div>
            </div> */}
            {/*begin::Form */}
            <form noValidate id='kt_modal_create_app_form'>
              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5 fw-semibold'>
                  <span className='required'>Article path</span>
                  {/* <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Specify your unique article title'
                  ></i> */}
                </label>
                {/* <div className='mb-2'>
                  <span>{ `(match with url in client app)` }</span>
                </div> */}
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  name='article-title'
                  placeholder=''
                  value={article?.path || ''}
                  onChange={(e) =>
                    updateData({
                      path: e.target.value.toLowerCase()
                    })
                  }
                />
              </div>
              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5 fw-semibold'>
                  <span className='required'>Localized path</span>
                </label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  name='article-title'
                  placeholder=''
                  value={article?.localized_path?.[selectedLanguage] || ''}
                  onChange={(e) =>
                    updateData({
                      localized_path: {
                        ...(article?.localized_path || {}),
                        [selectedLanguage]: e.target.value.toLowerCase()
                      }
                    })
                  }
                />
              </div>
              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                  <span className='required'>Article title</span>
                </label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  name='article-title'
                  placeholder=''
                  value={article?.title?.[selectedLanguage] || ''}
                  onChange={(e) =>
                    updateData({
                      title: {
                        ...(article?.title || {}),
                        [selectedLanguage]: e.target.value,
                      },
                    })
                  }
                />
              </div>

              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                  <span>Meta title</span>
                </label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  name='article-meta-title'
                  placeholder=''
                  value={article?.meta_title?.[selectedLanguage] || ''}
                  onChange={(e) =>
                    updateData({
                      meta_title: {
                        ...(article?.meta_title || {}),
                        [selectedLanguage]: e.target.value,
                      },
                    })
                  }
                />
              </div>

              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                  <span>Meta description</span>
                </label>
                <textarea
                  className='form-control form-control-lg form-control-solid'
                  name='article-meta-description'
                  placeholder=''
                  value={article?.meta_description?.[selectedLanguage] || ''}
                  onChange={(e) =>
                    updateData({
                      meta_description: {
                        ...(article?.meta_description || {}),
                        [selectedLanguage]: e.target.value,
                      },
                    })
                  }
                />
              </div>
              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                  <span>Keywords (comma separated)</span>
                </label>
                <textarea
                  className='form-control form-control-lg form-control-solid'
                  name='article-meta-description'
                  placeholder=''
                  value={keywords[selectedLanguage] || ''}
                  onChange={(e) =>
                    setKeywords({
                      ...keywords,
                      [selectedLanguage]: e.target.value,
                    })
                  }
                />
              </div>
              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                  <span>Cover image</span>
                </label>                
                <ImageInput onImageSelected={i => setNewCoverImage(i)} imageUrl={newCoverImage?.base64 ? newCoverImage.base64 : article?.cover_image} />
              </div>
              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                  <span>
                    Text
                  { selectedLanguage !== "fr" && <><span>&nbsp;&nbsp;&nbsp;</span><Button onClick={handleCopyContent} size="sm" className="ml-5">{'Copy from FR'}</Button></> }
                  <><span>&nbsp;&nbsp;&nbsp;</span><Button onClick={handleExportContent} size="sm" className="ml-5">{'Export to HTML'}</Button></>
                  <><span>&nbsp;&nbsp;&nbsp;</span><Button onClick={handleImportContent} size="sm" className="ml-5">{'Import from HTML'}</Button></>
                  </span>
                  {/* <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Specify your unique article title'
                  ></i> */}
                </label>
                { article && <div>
                  <Editor
                    editorState={editorStates[selectedLanguage]}
                    onChange={handleEditorChange}
                    entities={['link', 'image', 'button', 'attachment']} />
                  </div>
                  }
              </div>

              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2 d-row gap-4'>
                  <span>Visible on home page</span>
                  <input className='form-check-input' type='checkbox' checked={article?.home_page} onChange={e => updateData({
                    home_page: e.target.checked,
                  })} />
                </label>
              </div>

              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2 d-row gap-4'>
                  <span>Published</span>
                  <input className='form-check-input' type='checkbox' checked={article?.published} onChange={e => updateData({
                    published: e.target.checked,
                  })} />
                </label>
              </div>

              {/*begin::Actions */}
              <div className='d-flex flex-row-fluid justify-content-between align-items-center gap-2 gap-lg-3'>
                <div className='me-2'>
                  <button
                    type='button'
                    className='btn btn-lg'
                    data-kt-stepper-action='next'
                    onClick={onClose}
                  >
                    { 'Close' }
                  </button>
                </div>                
                <div className='d-flex gap-3'>
                { article?.id &&
                  <button
                    type='button'
                    className='btn btn-lg btn-danger'
                    data-kt-stepper-action='next'
                    onClick={onDelete}
                  >
                    { 'Delete' }
                  </button> }
                  <button
                    type='button'
                    className='btn btn-lg btn-primary'
                    data-kt-stepper-action='next'
                    onClick={onSave}
                  >
                    { article?.id ? 'Update' : 'Create' }
                  </button>
                </div>
              </div>
              {/*end::Actions */}
            </form>
            {/*end::Form */}
          </div>
          {/*end::Content */}
        </div>
        {/* end::Stepper */}
      </div>
    </Modal>,
    modalsRoot
  )
}

export default ArticleDetailModal
