import {useContext, useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import ToolbarCustom from '../../../_metronic/layout/components/toolbar/toolbars/ToolbarCustom'

import { ProductsContext } from '../../context'
import ResortDetailModal from './ResortDetailModal'

const ResortsPage = () => {
  
  const { resorts, resortsLoaded, fetchResorts, createResort, updateResort, deleteResort, regions, regionsLoaded, fetchRegions, productsLoaded, fetchProducts, products, updateMultipleProducts } = useContext(ProductsContext)

  const [currentResort, setCurrentResort] = useState(null)
  const [loading, setLoading] = useState(false)
  const [sortBy, setSortBy] = useState('name')
  const [sortOrder, setSortOrder] = useState('asc')
  const [filteredResorts, setFilteredResorts] = useState([])
  const [search, setSearch] = useState('')

  useEffect(() => {
    if(!resortsLoaded) {
      fetchResorts()
    }
  }, [resortsLoaded, fetchResorts])

  useEffect(() => {
    if(!regionsLoaded) {
      fetchRegions()
    }
  }, [regionsLoaded, fetchRegions])

  useEffect(() => {
    if(!productsLoaded) {
      fetchProducts()
    }
  }, [productsLoaded, fetchProducts])

  const onCreateClick = () => {
    setCurrentResort({
      id: '',
      name: {},
      description: {},
      region: '',
      country: '',
      coordinates: {
        latitude: '',
        longitude: ''
      },
    })
  }

  const onEditClick = (resort) => {
    setCurrentResort(resort)
  }

  const onDetailSubmit = async (data, productMatches) => {
    if(!data.name.fr) {
      alert('Resort name is required (missing french translation)')
      return
    }
    const languages = ['en', 'es', 'it']
    for(const lang of languages) {
      if(!data.name[lang]) {
        data.name[lang] = data.name.fr
      }
    }
    if(data.coordinates.latitude === '' || data.coordinates.longitude === '') {
      alert('Coordinates are required')
      return
    }
    let response
    let resortId
    if(!data.id) {
      response = await createResort(data)
      resortId = response.id
    } else {
      resortId = data.id
      response = await updateResort(resortId, data)
    }
    // update product options with resort product matches
    const productsUpdates = {}
    for(const match of productMatches) {
      const { product: productId, price } = match
      const product = itemWithId(products, productId)
      let isNewOption = true
      for(let optionKey in product.options) {
        const [optionResort] = optionKey.split('/')
        if(optionResort === resortId && price !== product.options[optionKey].price) {
          productsUpdates[productId] = {
            options: {
              ...product.options,
              [optionKey]: {
                ...product.options[optionKey],
                price
              }
            }
          }
          break
        } else if(optionResort === resortId) {
          isNewOption = false
          break
        }
      }
      if(isNewOption) {
        productsUpdates[productId] = {
          options: {
            ...(product.options || {}),
            [`${resortId}/${product.domain}/${product.duration}`]: {
              price
            }
          }
        }
      }
    }
    for(const product of products) {
      for(let optionKey in product.options) {
        const [optionResort] = optionKey.split('/')
        if(optionResort === resortId && !productMatches.find(m => m.product === product.id)) {
          if(!productsUpdates[product.id]) {
            productsUpdates[product.id] = {
              options: {
                ...product.options
              }
            }
          }
          delete productsUpdates[product.id].options[optionKey]
        }
      }
    }
    await updateMultipleProducts(productsUpdates)
    
    setCurrentResort(null)
  }

  const onDetailDelete = async () => {
    await deleteResort(currentResort.id)
    setCurrentResort(null)
  }

  const itemWithId = (array, id) => {
    return array.find(i => i.id === id)
  }

  const onSortClick = (property) => {
    if(sortBy !== property) {
      setSortBy(property)
      setSortOrder('asc')
    } else {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    }
  }

  useEffect(() => {
    let r = [...resorts]
    if(sortBy === 'name') {
      r.sort((a, b) => {
        if(a.name.fr < b.name.fr) return sortOrder === 'asc' ? -1 : 1
        if(a.name.fr > b.name.fr) return sortOrder === 'asc' ? 1 : -1
        return 0
      })
    } else if(sortBy === 'region') {
      r.sort((resortA, resortB) => {
        const a = itemWithId(regions, resortA.region)
        const b = itemWithId(regions, resortB.region)
        if(a?.name.fr < b?.name.fr) return sortOrder === 'asc' ? -1 : 1
        if(a?.name.fr > b?.name.fr) return sortOrder === 'asc' ? 1 : -1
        return 0

      })
    }
    if(search) {
      r = r.filter(resort => resort.name.fr.toLowerCase().includes(search.toLowerCase()))
    }
    setFilteredResorts(r)
  }, [resorts, regions, sortOrder, sortBy, search])

  return (
    <>
      <ToolbarCustom
        pageTitle={'Resorts'}
        primaryButton={resortsLoaded && { onClick: onCreateClick, title: 'Add' }}
        filterElements={[
        <div
          data-kt-search-element='form'
          className='w-100 position-relative'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen021.svg'
            className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 translate-middle-y ms-0'
          />
          <input
            type='text'
            className='form-control form-control-flush ps-10'
            name='search'
            value={search}
            placeholder='Search...'
            data-kt-search-element='input'
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>]}
        loading={!resortsLoaded} />
      {/* begin::Row */}
      <div className='row g-6 g-xl-9 mt-1'>
        {/* begin::Table */}
        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
          {/* begin::Table head */}
          <thead>
            <tr className='fw-bold text-muted'>
              <th className='w-15px'>
              </th>
              <th onClick={() => onSortClick('name')} className='min-w-150px'>{`Name${sortBy === 'name' ? sortOrder === 'desc' ? ' ↑' : ' ↓' : ''}`}</th>
              <th onClick={() => onSortClick('region')} className='min-w-140px'>{`Region${sortBy === 'region' ? sortOrder === 'desc' ? ' ↑' : ' ↓' : ''}`}</th>
              {/* <th className='min-w-100px text-end'>Actions</th> */}
            </tr>
          </thead>
          {/* end::Table head */}
          {/* begin::Table body */}
          <tbody>
          { resortsLoaded && filteredResorts.map((resort) => 
            <tr key={`resort_${resort.id}`}>
              <td>
              </td>
              <td>
                <div className='d-flex align-items-center'>
                  <div className='d-flex justify-content-start flex-column'>
                    <div className='text-dark fw-bold text-hover-primary fs-6 cursor-pointer' onClick={() => onEditClick(resort)}>
                      { `${resort.name.fr}` }
                    </div>
                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                      {resort.id}
                    </span>
                  </div>
                </div>
              </td>
              <td>
                {/* <div className='text-dark fw-bold text-hover-primary d-block fs-6'>
                  { itemWithId(regions, resort.region)?.name.fr || 'undefined' }
                </div> */}
                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                   { itemWithId(regions, resort.region)?.name.fr || 'undefined' }
                </span>
              </td>
              {/* <td>
                <div className='d-flex justify-content-end flex-shrink-0'>
                  <button
                  onClick={() => onEditClick(resort)}
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                  >
                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                  </button>
                </div>
              </td> */}
            </tr>
          )}
          </tbody>
          {/* end::Table body */}
        </table>
        {/* end::Table */}

        {/* begin::Col */}
        {/* <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          <CardsWidget20
            className='h-md-50 mb-5 mb-xl-10'
            description='Active Projects'
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
          <CardsWidget7
            className='h-md-50 mb-5 mb-xl-10'
            description='Professionals'
            icon={false}
            stats={357}
            labelColor='dark'
            textColor='gray-300'
          />
        </div> */}
        {/* end::Col */}

        {/* begin::Col */}
        {/* <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          <CardsWidget17 className='h-md-50 mb-5 mb-xl-10' />
          <ListsWidget26 className='h-lg-50' />
        </div> */}
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xxl-6'>
          {/* <EngageWidget10 className='h-md-100' /> */}
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gx-5 gx-xl-10'>
        {/* begin::Col */}
        <div className='col-xxl-6 mb-5 mb-xl-10'>
          {/* <app-new-charts-widget8 cssclassName="h-xl-100" chartHeight="275px" [chartHeightNumber]="275"></app-new-charts-widget8> */}
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xxl-6 mb-5 mb-xl-10'>
          {/* <app-cards-widget18 cssclassName="h-xl-100" image="./assets/media/stock/600x600/img-65.jpg"></app-cards-widget18> */}
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      {/* <div className='row gy-5 gx-xl-8'>
        <div className='col-xxl-4'>
          <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
        </div>
        <div className='col-xl-8'>
          <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
        </div>
      </div> */}
      {/* end::Row */}

      {/* begin::Row */}
      {/* <div className='row gy-5 g-xl-8'>
        <div className='col-xl-4'>
          <ListsWidget2 className='card-xl-stretch mb-xl-8' />
        </div>
        <div className='col-xl-4'>
          <ListsWidget6 className='card-xl-stretch mb-xl-8' />
        </div>
        <div className='col-xl-4'>
          <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />
        </div>
      </div> */}
      {/* end::Row */}

      {/* <div className='row g-5 gx-xxl-8'>
        <div className='col-xxl-4'>
          <MixedWidget8
            className='card-xxl-stretch mb-xl-3'
            chartColor='success'
            chartHeight='150px'
          />
        </div>
        <div className='col-xxl-8'>
          <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' />
        </div>
      </div> */}
      <ResortDetailModal
        show={Boolean(currentResort)}
        handleClose={() => setCurrentResort(null)}
        resort={currentResort}
        setResort={setCurrentResort}
        onSubmit={onDetailSubmit}
        onDelete={onDetailDelete} />
    </>
  )
}

const ResortsWrapper = () => {
  return (
    <>
      <ResortsPage />
    </>
  )
}

export default ResortsWrapper
