/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useContext, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import { ProductsContext } from '../../context'
import { Search } from '../../../_metronic/partials'
import ImageInput from '../../components/ImageInput'
import FileInput from '../../components/FileInput'
import { uploadFile } from '../../services/firebase'
// import {Editor, EditorState} from 'draft-js';
// import 'draft-js/dist/Draft.css';
import config from '../../../config.json'

const modalsRoot = document.getElementById('root-modals') || document.body

const ProductDetailModal = ({show, handleClose, product, setProduct, onSubmit, onDelete}) => {

  const { resorts, resortsLoaded, domains, domainsLoaded, durations, durationsLoaded } = useContext(ProductsContext)
  const [selectedLanguage, setSelectedLanguage] = useState('fr')
  const [resortSearchString, setResortSearchString] = useState('')
  const [resortSuggestions, setResortSuggestions] = useState([])
  const [options, setOptions] = useState([])
  const [newImage, setNewImage] = useState(null)
  const [newCoverImage, setNewCoverImage] = useState(null)
  const [brochure, setBrochure] = useState(null)
  const [cg, setCg] = useState(null)
  const [ipid, setIpid] = useState(null)


  const domainForEnvironment = {
    "development": "assurgliss-development.web.app",
    "staging": "assurgliss-staging.web.app",
    "production": "assuranceski.com"
  }

  useEffect(() => {
    if(!product || !product.options) {
      setNewImage(null)
      setNewCoverImage(null)
      setBrochure(null)
      setCg(null)
      setIpid(null)
      return
    }
    if(!resortsLoaded || !domainsLoaded || !durationsLoaded) {
      return 
    }
    let opts = []
    for(let key in product.options) {
      const [resortId] = key.split('/')
      opts.push({
        key: key,
        resort: resortId,
        price: product.options[key].price,
      })
    }
    opts.sort((a,b) => {
      const resortA = resorts.find(r => r.id === a.resort)
      const resortB = resorts.find(r => r.id === b.resort)
      if(!resortA) {
        return -1
      }
      if(!resortB) {
        return 1
      }
      if(resortA.name.fr < resortB.name.fr) return -1
      if(resortA.name.fr > resortB.name.fr) return 1
      return 0
    })
    setOptions([...opts])
  }, [product, resortsLoaded, domainsLoaded, durationsLoaded, resorts])

  const updateData = (fieldsToUpdate) => {
    const updatedData = {...product, ...fieldsToUpdate}
    setProduct(updatedData)
  }

  const handleOptionChange = (fieldsToUpdate, index) => {
    const opts = options
    opts[index] = {
      ...opts[index],
      ...fieldsToUpdate
    }
    setOptions([...opts])
  }

  const handleRemoveOption = (index) => {
    const opts = options
    opts.splice(index, 1)
    setOptions([...opts])
  }
  
  useEffect(() => {
    if(!resortSearchString) {
      setResortSuggestions([])
      return
    }
    let suggestions = []
    const currentResortsIds = product?.resorts || []
    for(let r of resorts) {
      if(r.name?.fr?.toLowerCase().includes(resortSearchString.toLowerCase())) {
        if(!currentResortsIds.includes(r.id)) {
          suggestions.push(r)
        }
      }
    }
    setResortSuggestions(suggestions)
  }, [resorts, resortSearchString])

  const handleSubmit = async () => {
    const data = {...product}
    if(!data.domain || !data.duration) {
      alert('Please select a domain and a duration')
      return
    }
    data.options = {}
    for(let opt of options) {
      if(!opt.resort) continue
      let key = `${opt.resort}/${data.domain}/${data.duration}`
      data.options[key] = {
        price: opt.price,
      }
    }
    if(newImage?.base64) {
      const uploadResponse = await uploadFile({ file: { name: newImage.name, type: newImage.type, size: newImage.size, base64: newImage.base64.split(',')[1]}})
      if(uploadResponse.url) {
        data.image = uploadResponse.url
      }
    }
    if(newCoverImage?.base64) {
      const uploadResponse = await uploadFile({ file: { name: newCoverImage.name, type: newCoverImage.type, size: newCoverImage.size, base64: newCoverImage.base64.split(',')[1]}})
      if(uploadResponse.url) {
        data.cover_image = uploadResponse.url
      }
    }
    if(brochure?.base64) {
      const uploadResponse = await uploadFile({ file: { name: brochure.name, type: brochure.type, size: brochure.size, base64: brochure.base64.split(',')[1]}, path: 'products/brochures'})
      if(uploadResponse.url) {
        data.brochure = uploadResponse.url
      }
    }
    if(cg?.base64) {
      const uploadResponse = await uploadFile({ file: { name: cg.name, type: cg.type, size: cg.size, base64: cg.base64.split(',')[1]}, path: 'products/cg'})
      if(uploadResponse.url) {
        data.cg = uploadResponse.url
      }
    }
    if(ipid?.base64) {
      const uploadResponse = await uploadFile({ file: { name: ipid.name, type: ipid.type, size: ipid.size, base64: ipid.base64.split(',')[1]}, path: 'products/ipid'})
      if(uploadResponse.url) {
        data.ipid = uploadResponse.url
      }
    }
    onSubmit(data)
  }

  const Collapsible = ({children, collapsedContent, title, collapsedByDefault = false}) => {
    const [collapsed, setCollapsed] = useState(collapsedByDefault)
    return (
      <div className={`collapsible ${collapsed ? 'collapsed' : ''} border-top border-bottom`}>
        <button
          type='button'
          className='icon-button collapse-button'
          onClick={() => setCollapsed(!collapsed)}
        >
          <img src={`/media/icons/duotune/arrows/arr0${collapsed ? '65' : '66'}.svg`} />
        </button>
        { !collapsed ? children : collapsedContent || <div>{title}</div>}

      </div>
    )
  }

  const handleAddOption = () => {
    setOptions([
      {
        resort: '',
        price: 0,
      },
      ...options
    ])
  }

  const fileNameFromUrl = (url) => {
    if(!url) return ''
    const parts = url.split('/')
    return decodeURIComponent(parts[parts.length - 1].split('?')[0] || '')
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div className='modal-header'>
        <div className='as-form-row align-items-center justify-content-between'>
          <h2>{ product?.id ? 'Update Product' : 'Create Product' }</h2>
          <div>
            <label className='mb-2'>
              <span>Language</span>
            </label>
            <div className='nav-group nav-group-outline nav-group-slim'>
              <label>
                <input
                  type='radio'
                  className='btn-check'
                  name='type'
                  value='fr'
                  checked={selectedLanguage === 'fr'}
                  onChange={(e) => setSelectedLanguage('fr')}
                />
                <span className='btn btn-sm btn-color-muted btn-active btn-active-primary'>
                  FR
                </span>
              </label>

              <label>
                <input type='radio' className='btn-check' name='type' value='en' checked={selectedLanguage === 'en'} onChange={(e) => setSelectedLanguage('en')} />
                <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                  EN
                </span>
              </label>

              <label>
                <input type='radio' className='btn-check' name='type' value='es' checked={selectedLanguage === 'es'} onChange={(e) => setSelectedLanguage('es')} />
                <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                  ES
                </span>
              </label>

              <label>
                <input type='radio' className='btn-check' name='type' value='it' checked={selectedLanguage === 'it'} onChange={(e) => setSelectedLanguage('it')} />
                <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                  IT
                </span>
              </label>
            </div>
          </div> 
        </div>
      </div>

      <div className='modal-body'>
        <div className='flex-row-fluid px-lg-5'>
                     
            <div className='fv-row'>
              <label className='mb-2'>
                <span className='required'>Product name</span>
              </label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='product-title'
                placeholder=''
                value={product?.name[selectedLanguage] || ''}
                onChange={(e) =>
                  updateData({
                    name: {
                      ...(product?.name || {}),
                      [selectedLanguage]: e.target.value,
                    },
                  })
                }
              />
            </div> 
            <div className='fv-row'>
              <label className='mb-2 mt-5'>
                <span>Slug (url)</span>
              </label>
              <input
                disabled={true}
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='product-slug'
                placeholder=''
                value={`https://${domainForEnvironment[config.environment]}/product/${product?.slug?.[selectedLanguage] || product?.id || ''}`}
              />
            </div>
            <div className='fv-row'>
              <div className='mt-5 fw-semibold mb-2 form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  value=''
                  name='active'
                  checked={product?.active}
                  onChange={e => updateData({ active: e.target.checked })}
                />
                <label className='form-check-label'>{ product?.active ? 'Active' : 'Disabled' }</label>
              </div>
            </div>
                     
            <div className='fv-row mb-6'>
              <label className='mb-2'>
                <span className='required'>Intro text</span>
              </label>
              <textarea
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='product-intro'
                placeholder=''
                value={product?.intro?.[selectedLanguage] || ''}
                onChange={(e) =>
                  updateData({
                    intro: {
                      ...(product?.intro || {}),
                      [selectedLanguage]: e.target.value,
                    },
                  })
                }
              />
            </div>
                     
            <div className='fv-row mb-6'>
              <label className='mb-2'>
                <span className='required'>Description</span>
              </label>
              <textarea
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='product-description'
                placeholder=''
                value={product?.description?.[selectedLanguage] || ''}
                onChange={(e) =>
                  updateData({
                    description: {
                      ...(product?.description || {}),
                      [selectedLanguage]: e.target.value,
                    },
                  })
                }
              />
            </div>

            <div className='fv-row mb-6'>
              <label className='mb-2'>
                <span className='required'>Domain</span>
              </label>
              <select className='form-select form-select-solid form-select-lg' name="domain" id="domain" value={product?.domain || ""} onChange={e => updateData({
                    domain: e.target.value}
                  )}>
                <option disabled value="">{"Select domain"}</option>
                { domains.map((d, di) => 
                  <option key={`domain_option_${di}`} value={d.id}>{d.title.fr}</option>
                )}
              </select>
            </div>

            <div className='fv-row mb-6'>
              <label className='mb-2'>
                <span className='required'>Duration</span>
              </label>
              <select className='form-select form-select-solid form-select-lg' name="duration" id="duration" value={product?.duration || ""} onChange={e => updateData({
                  duration: e.target.value
                }
              )}>
                <option disabled value="">{"Select duration"}</option>
                { durations.map((d, di) => 
                  <option key={`duration_option_${di}`} value={d.id}>{d.title.fr}</option>
                )}
              </select>
            </div>

            <div className='fv-row mb-6'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>CG</span>
              </label>                
              <FileInput onFileSelected={i => setCg(i)} fileUrl={product?.cg} fileName={cg?.name ? cg.name : fileNameFromUrl(product?.cg)} onRemove={() => {
                setCg(null)
                updateData({ cg: "" })
              }} />
            </div>



            <div className='fv-row mb-6'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>IPID</span>
              </label>                
              <FileInput onFileSelected={i => setIpid(i)} fileUrl={product?.ipid} fileName={ipid?.name ? ipid.name : fileNameFromUrl(product?.ipid)} onRemove={() => {
                setIpid(null)
                updateData({ ipid: "" })
              }} />
            </div>

            <div className='fv-row mb-6'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Image</span>
              </label>                
              <ImageInput onImageSelected={i => setNewImage(i)} imageUrl={newImage?.base64 ? newImage.base64 : product?.image} onRemove={() => {
                setNewImage(null)
                updateData({ image: "" })
              }} />
            </div>

            <div className='fv-row mb-6'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Cover image</span>
              </label>                
              <ImageInput onImageSelected={i => setNewCoverImage(i)} imageUrl={newCoverImage?.base64 ? newCoverImage.base64 : product?.cover_image} onRemove={() => {
                setNewCoverImage(null)
                updateData({ cover_image: "" })
              }} />
            </div>

            <div className='fv-row mb-6'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Brochure</span>
              </label>                
              <FileInput onFileSelected={i => setBrochure(i)} fileUrl={product?.brochure} fileName={brochure?.name ? brochure.name : fileNameFromUrl(product?.brochure)} onRemove={() => {
                setBrochure(null)
                updateData({ brochure: "" })
              }} />
            </div>

            { product && <div className='fv-row'>
              <div className='mt-5 fw-semibold mb-2 form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                <span>{ `Internal ID: ${product.internal_id}` }</span>
              </div>
            </div> }
            { Collapsible({ collapsedByDefault: true, collapsedContent: <div className='fv-row mt-5'>
                <label className='form-row align-center mb-5'>
                  <span>Guarantees</span>
                </label>
              </div>, children: 
              <div className='fv-row mt-5'>
                <label className='form-row align-center mb-5'>
                  <span>Guarantees</span>
                  <button
                    type='button'
                    className='icon-button'
                    onClick={() => {
                      const newGuarantees = [
                        ...(product?.guarantees || []),
                      ]
                      newGuarantees.push({})
                      updateData({
                        guarantees: newGuarantees
                      })
                    }}
                  >
                    <img src="/media/icons/duotune/general/gen035.svg" />
                  </button>
                </label>
                { [...(product?.guarantees || [])].map((guarantee, gi) =>
                  <div key={`guarantee_${gi}`} className='border-bottom border-top pb-5 pt-5'>
                    <div className='fv-row'>
                      <label className='mb-2'>
                        <span className='required'>{ `Guarantee ${gi+1} title` }</span>
                        <button
                          type='button'
                          className='icon-button'
                          data-kt-stepper-action='next'
                          onClick={() => {
                            const newGuarantees = [
                              ...(product?.guarantees || []),
                            ]
                            newGuarantees.splice(gi, 1)
                            updateData({
                              guarantees: newGuarantees
                            })
                          }}
                        >
                          <img src="/media/icons/duotune/general/gen027.svg" />
                        </button>
                      </label>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        name='product-title'
                        placeholder=''
                        value={guarantee.title?.[selectedLanguage] || ''}
                        onChange={(e) => {
                          const newGuarantees = [
                            ...(product?.guarantees || []),
                          ]
                          newGuarantees[gi] = {
                            ...newGuarantees[gi],
                            title: {
                              ...newGuarantees[gi].title,
                              [selectedLanguage]: e.target.value
                            }
                          }
                          updateData({
                            guarantees: newGuarantees
                          })
                        }}
                      />
                    </div>
                    <div className='fv-row mt-2'>
                      <label className='mb-2'>
                          <span className='required'>{ `Guarantee ${gi+1} text` }</span>
                        </label>
                        <textarea
                          type='text'
                          className='form-control form-control-lg form-control-solid'
                          name='product-text'
                          placeholder=''
                          value={guarantee.text?.[selectedLanguage] || ''}
                          onChange={(e) => {
                            const newGuarantees = [
                              ...(product?.guarantees || []),
                            ]
                            newGuarantees[gi] = {
                              ...newGuarantees[gi],
                              text: {
                                ...newGuarantees[gi].text,
                                [selectedLanguage]: e.target.value
                              }
                            }
                            updateData({
                              guarantees: newGuarantees
                            })
                          }}
                        />
                      </div>
                      
                    </div>
                )}
              </div> }
            )}
            { Collapsible({ collapsedByDefault: true, collapsedContent: <div className='fv-row mt-5'>
                <label className='form-row align-center mb-5'>
                  <span>Exclusions</span>
                </label>
              </div>, children:
              <div className='fv-row mt-5'>
                <label className='mb-2'>
                  <span>Exclusions</span>
                </label>
                <div className='pb-5'>
                  <div className='fv-row'>
                  <label className='mb-2'>
                    <span className='required'>Title</span>
                  </label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='product-title'
                    placeholder=''
                    value={product?.exclusions?.title?.[selectedLanguage] || ''}
                    onChange={(e) => {
                      updateData({
                        exclusions: {
                          ...(product?.exclusions || {}),
                          title: {
                            ...(product?.exclusions?.title || {}),
                            [selectedLanguage]: e.target.value
                          }
                        }
                      })
                    }}
                  />
                </div>
                <div className='fv-row'>
                <label className='mb-2 mt-2'>
                    <span className='required'>Text</span>
                  </label>
                  <textarea
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='product-text'
                    placeholder=''
                    value={product?.exclusions?.text?.[selectedLanguage] || ''}
                    onChange={(e) => {
                      updateData({
                        exclusions: {
                          ...(product?.exclusions || {}),
                          text: {
                            ...(product?.exclusions?.text || {}),
                            [selectedLanguage]: e.target.value
                          }
                        }
                      })
                    }}
                  />
                  </div>
                </div>
              </div> }
            )}

            { Collapsible({ collapsedContent: <div className='fv-row mt-5'>
                <label className='form-row align-center mb-5'>
                  <span>Options</span>
                </label>
              </div>, children: 
              <div className='row mt-5'>
                <label className='mb-2'>Options</label>
                <div className='suggestions-input'>
                  <button
                    type='button'
                    className='btn btn-sm btn-primary'
                    data-kt-stepper-action='next'
                    onClick={handleAddOption}
                  >
                    Add option
                  </button>
                </div>
                <div className='resorts-table mt-5'>
                  { options.map((option, optionIndex) =>
                    <div className='option-row border-top pt-2 pb-2' key={`option_row_${optionIndex}`}>
                      <div>
                        <span>Resort</span>
                        <select name="resort" id="resort" value={option.resort} onChange={e => handleOptionChange({ resort: e.target.value }, optionIndex)}>
                          <option disabled value="">{"Select resort"}</option>
                          { resorts.sort((a, b) => {
                            if(a.name.fr < b.name.fr) return -1
                            if(a.name.fr > b.name.fr) return 1
                            return 0
                          }).map((r, ri) => 
                            <option key={`resort_option_${ri}`} value={r.id}>{r.name.fr}</option>
                          )}
                        </select>
                      </div>
                      <div>
                        <span>Price</span>
                        <input
                          type='number'
                          className='form-control form-control-sm form-control-solid w-100px'
                          name='resort-price'
                          placeholder=''
                          value={option.price}
                          onChange={e => handleOptionChange({ price: parseFloat(e.target.value) }, optionIndex)}
                        />
                      </div>
                      <div>
                        <button
                          type='button'
                          className='icon-button'
                          data-kt-stepper-action='next'
                          onClick={() => handleRemoveOption(optionIndex)}
                        >
                          <img src="/media/icons/duotune/general/gen027.svg" />
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div> }
            )}

            {/*begin::Actions */}
            <div className='d-flex flex-row-fluid justify-content-between align-items-center gap-2 gap-lg-3 mt-10'>
              <div className='me-2'>
                <button
                  type='button'
                  className='btn btn-lg'
                  data-kt-stepper-action='next'
                  onClick={handleClose}
                >
                  { 'Close' }
                </button>
              </div>                
              <div className='d-flex gap-3'>
              { product?.id &&
                <button
                  type='button'
                  className='btn btn-lg btn-danger'
                  data-kt-stepper-action='next'
                  onClick={onDelete}
                >
                  { 'Delete' }
                </button> }
                <button
                  type='button'
                  className='btn btn-lg btn-primary'
                  data-kt-stepper-action='next'
                  onClick={handleSubmit}
                >
                  { product?.id ? 'Update' : 'Create' }
                </button>
              </div>
            </div>
            {/*end::Actions */}
        </div>
        {/*end::Content */}
      </div>
    </Modal>,
    modalsRoot
  )
}

export default ProductDetailModal
