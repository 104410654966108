import moment from 'moment'
import {useContext, useEffect, useState} from 'react'
import { useLocation } from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'
import ToolbarCustom from '../../../_metronic/layout/components/toolbar/toolbars/ToolbarCustom'

import { CustomersContext, ProductsContext } from '../../context'
import CustomerDetailModal from '../Customers/CustomerDetailModal'
import PurchaseDetailModal from './PurchaseDetailModal'

const PurchasesPage = () => {
  
  const {
    customers,
    customersLoaded,
    fetchCustomers,
    purchases,
    purchasesLoaded,
    fetchPurchases,
  } = useContext(CustomersContext)

  const {
    resorts,
    resortsLoaded,
    fetchResorts,
    domains,
    domainsLoaded,
    fetchDomains,
    durations,
    durationsLoaded,
    fetchDurations,
    products,
    productsLoaded,
    fetchProducts,
  } = useContext(ProductsContext)

  const location = useLocation()

  const [currentPurchase, setCurrentPurchase] = useState(null)
  const [filteredPurchases, setFilteredPurchaes] = useState([])
  const [validPurchases, setValidPurchases] = useState([])
  const [sortBy, setSortBy] = useState('date')
  const [sortOrder, setSortOrder] = useState('asc')
  const [showExportModal, setShowExportModal] = useState(false)
  const [exportRangeStart, setExportRangeStart] = useState(null)
  const [exportRangeEnd, setExportRangeEnd] = useState(null)
  const [search, setSearch] = useState('')
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(20)

  useEffect(() => {
    if(!customersLoaded) {
      fetchCustomers()
    }
  }, [customersLoaded, fetchCustomers])

  useEffect(() => {
    if(!purchasesLoaded) {
      fetchPurchases()
    }
  }, [purchasesLoaded, fetchPurchases])

  useEffect(() => {
    if(!resortsLoaded) {
      fetchResorts()
    }
  }, [resortsLoaded, fetchResorts])

  useEffect(() => {
    if(!domainsLoaded) {
      fetchDomains()
    }
  }, [domainsLoaded, fetchDomains])

  useEffect(() => {
    if(!durationsLoaded) {
      fetchDurations()
    }
  }, [durationsLoaded, fetchDurations])

  useEffect(() => {
    if(!productsLoaded) {
      fetchProducts()
    }
  }, [productsLoaded, fetchProducts])

  const onViewClick = (purchase) => {
    setCurrentPurchase(purchase)
  }

  const emailForCustomer = (customer) => {
    if(customer.email) {
      return customer.email
    }
    const customersPurchases = purchases.filter(p => p.user === customer.uid)
    if(customersPurchases.length > 0) {
      return customersPurchases[0].buyer.email
    }
    return "Anonymous"
  }

  const onSortClick = (property) => {
    if(sortBy !== property) {
      setSortBy(property)
      setSortOrder('asc')
    } else {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    }
  }

  useEffect(() => {

    const buyerName = (purchase) => {
      return `${purchase.buyer.firstname} ${purchase.buyer.lastname}`
    }
    let fp = [...validPurchases]
    if(sortBy === 'date') {
      fp.sort((a, b) => {
        const aDate = a.paid_at || a.meta?.updated || a.meta?.created || 0
        const bDate = b.paid_at || b.meta?.updated || b.meta?.created || 0
        if(aDate < bDate) return sortOrder === 'asc' ? 1 : -1
        if(aDate > bDate) return sortOrder === 'asc' ? -1 : 1
        return 0
      })
    } else if(sortBy === 'customer') {
      fp.sort((a, b) => {
        if(buyerName(a)?.toLowerCase() > buyerName(b)?.toLowerCase()) return sortOrder === 'asc' ? -1 : 1
        if(buyerName(a)?.toLowerCase() < buyerName(b)?.toLowerCase()) return sortOrder === 'asc' ? 1 : -1
        return 0
      })
    } else if(sortBy === 'price') {
      fp.sort((a, b) => {
        if(a.amount > b.amount) return sortOrder === 'asc' ? -1 : 1
        if(a.amount < b.amount) return sortOrder === 'asc' ? 1 : -1
        return 0
      })
    } else if(sortBy === 'product') {
      fp.sort((a, b) => {
        if(a.product_name > b.product_name) return sortOrder === 'asc' ? -1 : 1
        if(a.product_name < b.product_name) return sortOrder === 'asc' ? 1 : -1
        return 0
      })
    } else if(sortBy === 'refunded') {
      fp.sort((a, b) => {
        if(a.status === "refunded" && b.status !== "refunded") return sortOrder === 'asc' ? -1 : 1
        if(b.status === "refunded" && a.status !== "refunded") return sortOrder === 'asc' ? 1 : -1
        return 0
      })
    }

    if(search) {
      const searchTokens = search.split(' ').filter(t => t.length > 0)
      fp = fp.filter(purchase => {
        const fields = [purchase.buyer.firstname, purchase.buyer.lastname, purchase.buyer.email, purchase.id]
        // check if all tokens are found in at least one field
        for(let token of searchTokens) {
          if(!fields.find(f => f.toLowerCase().includes(token.toLowerCase()))) {
            return false
          }
        }
        return true
      })
    }
    setFilteredPurchaes(fp)
  }, [customers, validPurchases, sortOrder, sortBy, search])

  useEffect(() => {

    let fp = []
    for(let p of purchases) {
      if(p.status === 'paid' || p.status === 'refunded') {
        fp.push(p)
      }
    }
    setValidPurchases(fp)
  }, [purchases])

  useEffect(() => {
    // adjust current page if it is out of bounds
    const maxPages = Math.ceil(filteredPurchases.length / pageSize)
    if(maxPages > 0 && currentPage >= maxPages) {
      setCurrentPage(maxPages - 1)
    }
  }, [currentPage, pageSize])

  const itemWithId = (array, id) => {
    return array.find(i => i.id === id)
  }

  const formattedPrice = (price) => {
    return `€ ${parseFloat((price / 100)).toFixed(2)}`.replace('.', ',')
  }

  const promptExport = () => {
    if(!productsLoaded || !resortsLoaded || !domainsLoaded || !durationsLoaded) {
      alert('Wait for data to be loaded. Please try again in a moment.')
      return
    }
    setShowExportModal(true)
  }

  const handleExport = (range) => {

    /** Fields to export */
    // Product name (app)
    // Product ID (app)
    // Product name (WTW)
    // Product ID (WTW/Mutuaide)
    // Purchase date
    // Amount paid
    // *ppv unitaire
    // *com distrib unitaire
    // *com distrib a percevoir
    // Number of insured persons
    // Customer email
    // Customer name
    // Insured persons
    // Resort
    // Domain
    // Duration
    // Dates
    // Proofs of purchase links
    // Contract link
    // Refunded
    // Refunded at
    // Amount refunded

    const csvHeader = 'Purchase ID;Product name (WebApp);Product ID (WebApp);Product name (WTW);Product ID (WTW / Mutuaide);Purchase date;Amount paid;PPV unitaire;Com distrib unitaire;Com distrib à percevoir;Nombre de produits achetés;Customer email;Customer name;Customer company;Insured persons;Resort;Domain;Duration;Dates;Proofs of purchase;Contract url;Refunded;Refunded at;Refunded amount\n'

    if(!productsLoaded || !resortsLoaded || !domainsLoaded || !durationsLoaded) {
      alert('Wait for data to be loaded. Please try again in a moment.')
      return
    }

    const EXPORT_DATE_FORMAT = 'YYYY-MM-DD HH:mm'
    const EXPORT_DATE_FORMAT_SHORT = 'YYYY-MM-DD'

    let csv = csvHeader
    let exportCount = 0
    let totalPaid = 0
    const sortedPurchases = [...validPurchases]
    sortedPurchases.sort((a, b) => {
      const aDate = a.paid_at || a.meta?.updated || a.meta?.created || 0
      const bDate = b.paid_at || b.meta?.updated || b.meta?.created || 0
      if(aDate < bDate) return -1
      if(aDate > bDate) return 1
      return 0
    })
    for(let purchase of sortedPurchases) {
      if(range && exportRangeStart && exportRangeEnd) {
        const purchaseMoment = moment(purchase.paid_at || purchase.meta?.updated || purchase.meta?.created)
        const purchaseYear = purchaseMoment.year()
        const purchaseMonth = purchaseMoment.month() + 1
        const purchaseDay = purchaseMoment.date()

        const [exportRangeStartYear, exportRangeStartMonth, exportRangeStartDay] = exportRangeStart.split('-').map(e => parseInt(e))
        const [exportRangeEndYear, exportRangeEndMonth, exportRangeEndDay] = exportRangeEnd.split('-').map(e => parseInt(e))

        if(purchaseYear < exportRangeStartYear || purchaseYear > exportRangeEndYear) {
          continue
        }
        if(purchaseYear === exportRangeStartYear && purchaseMonth < exportRangeStartMonth) {
          continue
        }
        if(purchaseYear === exportRangeEndYear && purchaseMonth > exportRangeEndMonth) {
          continue
        }
        if(purchaseYear === exportRangeStartYear && purchaseMonth === exportRangeStartMonth && purchaseDay < exportRangeStartDay) {
          continue
        }
        if(purchaseYear === exportRangeEndYear && purchaseMonth === exportRangeEndMonth && purchaseDay > exportRangeEndDay) {
          continue
        }
      }

      const product = itemWithId(products || [], purchase.product)
      const resort = itemWithId(resorts || [], purchase.resort)
      const domain = itemWithId(domains || [], purchase.domain)
      const duration = itemWithId(durations || [], purchase.duration)
      const purchaseId = purchase.id
      const productName = product?.name.fr || purchase.product_name
      const productId = product?.id || purchase.product
      const productNameWtw = product?.name.fr || purchase.product_name
      const productIdWtw = product?.internal_id || 'N/A'
      const purchaseDate = purchase.paid_at ? moment(purchase.paid_at).format(EXPORT_DATE_FORMAT) : purchase.meta?.updated ? moment(purchase.meta.updated).format('DD/MM/YYYY HH:mm') : 'N/A'
      const amountPaid = formattedPrice(purchase.amount)
      const datesArray = purchase.dates_formatted || purchase.dates?.map(d => moment(d).format('DD/MM/YYYY')) || []
      // const productOption = product.options[`${purchase.resort}/${purchase.domain}/${purchase.duration}`]
      const distributorUnitPrice = 'N/A' // productUnitPrice * 0.8
      const distributorAmount = 'N/A' // distributorUnitPrice * purchase.quantity
      const numberOfProducts = purchase.people.length *  (duration.dates_required ? datesArray.length : 1)
      const productUnitPrice = formattedPrice(purchase.amount / numberOfProducts) // productOption?.price ? formattedPrice(productOption.price * 100) : formattedPrice()
      const customerEmail = purchase.buyer.email
      const customerName = `${purchase.buyer.firstname} ${purchase.buyer.lastname}`
      const customerCompany = purchase.buyer.comany_name || purchase.buyer.companyName || ''
      const insuredPersons = purchase.people.map(p => `${p.firstname} ${p.lastname}`).join(', ')
      const resortName = resort.name.fr
      const domainName = domain.title.fr
      const durationName = duration.title.fr
      const dates = datesArray.join(', ')
      const proofsOfPurchase = (purchase.proofs_of_purchase || []).join(', ')
      const contractUrl = purchase.contract_url || 'N/A'
      const refunded = purchase.status === "refunded" ? "YES" : "NO"
      const refundedAt = purchase.status === "refunded" ? purchase.meta.updated ? moment(purchase.meta.updated).format(EXPORT_DATE_FORMAT) : 'N/A' : ''
      const refundedAmount = purchase.status === "refunded" ? purchase.amount_refunded ? formattedPrice(purchase.amount_refunded) : 'N/A' : ''

      csv += `${purchaseId};${productName};${productId};${productNameWtw};${productIdWtw};${purchaseDate};${amountPaid};${productUnitPrice};${distributorUnitPrice};${distributorAmount};${numberOfProducts};${customerEmail};${customerName};${customerCompany};${insuredPersons};${resortName};${domainName};${durationName};${dates};${proofsOfPurchase};${contractUrl};${refunded};${refundedAt};${refundedAmount}\n`
      exportCount++
      totalPaid += purchase.amount
    }
    if(exportCount > 0) {
      const totalPaidColumnIndex = 6
      // add row for totals for amount paid and com distrib a percevoir
      csv += `${Array(totalPaidColumnIndex).fill('').join(';')};${formattedPrice(totalPaid)}\n`
    }
    const a = document.createElement('a')
    const fileName = 'Assuranceski-Purchases-Export'
    // print range in suffix or current date if no range in format DD-MM-YYYY
    const fileSuffix = range ? `${exportRangeStart}_${exportRangeEnd}` : `all-(${moment().format(EXPORT_DATE_FORMAT_SHORT)})`
    a.download = `${fileName}-${fileSuffix}.csv`
    a.href = 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv)
    a.click()
    setShowExportModal(false)
  }

  const currentPaginationButtons = () => {
    const maxPages = Math.ceil(filteredPurchases.length / pageSize)
    const pageButtons = []
    const maxButtons = 5
    const middleButton = Math.floor(maxButtons / 2)
    let startButton = Math.max(0, Math.min(maxPages - maxButtons, currentPage - middleButton))
    let endButton = Math.min(maxPages, startButton + maxButtons)
    if(endButton - startButton < maxButtons) {
      startButton = Math.max(0, endButton - maxButtons)
    }
    for(let i = startButton; i < endButton; i++) {
      pageButtons.push(i)
    }
    if(pageButtons[0] > 0) {
      pageButtons[0] = 0
    }
    if(pageButtons[pageButtons.length - 1] < maxPages - 1) {
      pageButtons[pageButtons.length - 1] = maxPages - 1
    }
    return pageButtons
  }

  return (
    <>
      <ToolbarCustom
        pageTitle={'Purchases'}
        filterElements={[
        <div
          data-kt-search-element='form'
          className='w-300px position-relative'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen021.svg'
            className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 translate-middle-y ms-0'
          />
          <input
            type='text'
            className='form-control form-control-flush ps-9 pe-9'
            name='search'
            value={search}
            placeholder='Customer name, email, purchase ID'
            data-kt-search-element='input'
            onChange={(e) => setSearch(e.target.value)}
          />
          {/* clear search field */}
          {search && (
            <div
              className='btn btn-flush btn-active-color-primary position-absolute top-50 ms-0 translate-middle-y end-0'
              onClick={() => setSearch('')}
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500'
              />
            </div>
          )}
        </div>,
        <div
          className='w-100 position-relative'
        >
          <div className='btn btn-primary btn-sm' onClick={promptExport}>Export</div>
        </div>]}
        loading={!purchasesLoaded}
        />
      {/* begin::Row */}

      <div className='row g-6 g-xl-9 mt-1'>

        {/* begin::Pagination */}
        <div className='d-flex justify-content-end'>
          <div className='d-flex align-items-center text-wrap-nowrap flex-row'>
            <span className='text-muted fs-7 mr-4'>Items per page</span>
            <select
              className='form-select form-select-sm form-select-solid mr-4'
              value={`${pageSize}`}
              onChange={(e) => setPageSize(parseInt(e.target.value))}
            >
              <option value='10'>10</option>
              <option value='20'>20</option>
              <option value='50'>50</option>
              <option value='100'>100</option>
            </select>
          </div>
          {/* Page number buttons, show max 5 page number buttons, with current page in the middle when applicable */}
          <div className='d-flex align-items-center flex-row'>
            
            {currentPaginationButtons().map((page) => (
              <button
                key={`page_${page}`}
                onClick={() => setCurrentPage(page)}
                className={`btn btn-icon btn-sm me-2 ${currentPage === page ? 'btn-primary' : 'btn-outline'}`}
              >
                {page + 1}
              </button>
            ))}
          </div>
        </div>
        {/* end::Pagination */}

        {/* begin::Table */}
        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
          {/* begin::Table head */}
          <thead>
            <tr className='fw-bold text-muted'>
              <th className='w-15px'>
              </th>
              <th onClick={() => onSortClick('product')} className='min-w-150px'>{`Product${sortBy === 'product' ? sortOrder === 'desc' ? '↑' : '↓' : ''}`}</th>
              <th onClick={() => onSortClick('price')} className='min-w-120px'>{`Price${sortBy === 'price' ? sortOrder === 'desc' ? '↑' : '↓' : ''}`}</th>
              <th onClick={() => onSortClick('customer')} className='min-w-120px'>{`Customer${sortBy === 'customer' ? sortOrder === 'desc' ? '↑' : '↓' : ''}`}</th>
              <th onClick={() => onSortClick('date')} className='min-w-200px text-center'>{`Purchase date${sortBy === 'date' ? sortOrder === 'desc' ? '↑' : '↓' : ''}`}</th>
              <th onClick={() => onSortClick('refunded')} className='min-w-80px text-center'>{`Refunded${sortBy === 'refunded' ? sortOrder === 'desc' ? '↑' : '↓' : ''}`}</th>
              <th className='min-w-100px text-end'></th>
            </tr>
          </thead>
          {/* end::Table head */}

          {/* begin::Table body */}
          <tbody>

            {purchasesLoaded && filteredPurchases.slice(pageSize*currentPage, pageSize*(currentPage + 1)).map((purchase) => 
              <tr key={`purchase_${purchase.id}`}>
                <td>
                </td>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start flex-column'>
                      <div className='text-dark fw-bold fs-6'>
                        { purchase.product_name }
                      </div>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        { `${itemWithId(resorts || [], purchase.resort)?.name?.fr || ''}, ${itemWithId(domains || [], purchase.domain)?.title?.fr || ''}, ${itemWithId(durations || [], purchase.duration)?.title?.fr || ''}` }
                      </span>
                      <span className='text-muted fw-semibold text-muted d-block fs-9'>
                        { purchase.id }
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <span className='text-muted fw-semibold text-muted d-block fs-7'>
                    { `${(purchase.amount / 100).toFixed(2)}€` }
                  </span>
                </td>
                <td>
                  <span className='text-muted fw-semibold d-block fs-7'>
                    { `${purchase.buyer.firstname} ${purchase.buyer.lastname}` }
                  </span>
                  <span className='text-muted fw-semibold d-block fs-9'>
                    { purchase.buyer.email }
                  </span>
                  <span className='text-muted fw-semibold d-block fs-9'>
                    { purchase.user }
                  </span>
                </td>
                <td>
                  <span className='text-muted fw-semibold text-muted d-block fs-7 text-center'>
                    {/* { purchase.paid_at } */}
                    { purchase.paid_at ? moment(purchase.paid_at).format('DD/MM/YYYY HH:mm') : purchase.meta?.updated ? moment(purchase.meta.updated).format('DD/MM/YYYY HH:mm') : 'N/A' }
                  </span>
                </td>
                <td>
                  <span className='text-muted fw-semibold text-muted d-block fs-7 text-center'>
                    {  purchase.status === "refunded" ? "YES" : "NO" }
                  </span>
                </td>
                <td>
                  <div className='d-flex justify-content-end flex-shrink-0'>
                    <button
                    onClick={() => onViewClick(purchase)}
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                      Detail
                      {/* <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' /> */}
                    </button>
                    {/* <button
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen027.svg'
                        className='svg-icon-3'
                      />
                    </button> */}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
          {/* end::Table body */}

          {/* begin::Empty search results label */}
          {search && filteredPurchases.length === 0 && (
            <tr>
              <td className='text-center text-gray-600 fw-bold py-10' colSpan='6'>
                {`No purchases found for query "${search}"`}
              </td>
            </tr>
          )}
          {/* end::Empty search results label */}

        </table>
        {/* end::Table */}
        {/* begin::Col */}
        {/* <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          <CardsWidget20
            className='h-md-50 mb-5 mb-xl-10'
            description='Active Projects'
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
          <CardsWidget7
            className='h-md-50 mb-5 mb-xl-10'
            description='Professionals'
            icon={false}
            stats={357}
            labelColor='dark'
            textColor='gray-300'
          />
        </div> */}
        {/* end::Col */}

        {/* begin::Col */}
        {/* <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          <CardsWidget17 className='h-md-50 mb-5 mb-xl-10' />
          <ListsWidget26 className='h-lg-50' />
        </div> */}
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xxl-6'>
          {/* <EngageWidget10 className='h-md-100' /> */}
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gx-5 gx-xl-10'>
        {/* begin::Col */}
        <div className='col-xxl-6 mb-5 mb-xl-10'>
          {/* <app-new-charts-widget8 cssclassName="h-xl-100" chartHeight="275px" [chartHeightNumber]="275"></app-new-charts-widget8> */}
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xxl-6 mb-5 mb-xl-10'>
          {/* <app-cards-widget18 cssclassName="h-xl-100" image="./assets/media/stock/600x600/img-65.jpg"></app-cards-widget18> */}
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      {/* <div className='row gy-5 gx-xl-8'>
        <div className='col-xxl-4'>
          <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
        </div>
        <div className='col-xl-8'>
          <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
        </div>
      </div> */}
      {/* end::Row */}

      {/* begin::Row */}
      {/* <div className='row gy-5 g-xl-8'>
        <div className='col-xl-4'>
          <ListsWidget2 className='card-xl-stretch mb-xl-8' />
        </div>
        <div className='col-xl-4'>
          <ListsWidget6 className='card-xl-stretch mb-xl-8' />
        </div>
        <div className='col-xl-4'>
          <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />
        </div>
      </div> */}
      {/* end::Row */}

      {/* <div className='row g-5 gx-xxl-8'>
        <div className='col-xxl-4'>
          <MixedWidget8
            className='card-xxl-stretch mb-xl-3'
            chartColor='success'
            chartHeight='150px'
          />
        </div>
        <div className='col-xxl-8'>
          <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' />
        </div>
      </div> */}
      <PurchaseDetailModal
        show={Boolean(currentPurchase)}
        handleClose={() => setCurrentPurchase(null)}
        purchase={currentPurchase} />
      {/** begin:export prompt modal */}
      {/** prompt to select dates from - to */ }
      <>
        <div
          className={`modal fade ${showExportModal ? 'show' : 'pe-none'} d-block`}
          id='exportModal'
          tabIndex='-1'
          aria-hidden={true}
        >
          <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-md'>
            <div className={`modal-content ${!showExportModal ? 'pe-none' : ''}`}>
              <div className='modal-header'>
                <h2 className='fw-bolder'>Export Purchases</h2>
                <div className='btn btn-icon btn-sm btn-active-icon-primary' data-bs-dismiss='modal' onClick={() => setShowExportModal(false)}>
                  <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
                </div>
              </div>
              <div className='modal-body'>
                <div className='d-flex flex-column'>
                  <div className='fw-bold fs-6 mb-3'>Select date range</div>
                  <div className='d-flex flex-column'>
                    <div className='d-flex flex-row mb-3'>
                      <div className='d-flex flex-column flex-grow-1 pe-3'>
                        <label className='fw-bold fs-6 mb-1'>From</label>
                        <input type='date' className='form-control form-control-solid' onChange={(e) => setExportRangeStart(e.target.value)} />
                      </div>
                      <div className='d-flex flex-column flex-grow-1 ps-3'>
                        <label className='fw-bold fs-6 mb-1'>To</label>
                        <input type='date' className='form-control form-control-solid' onChange={(e) => setExportRangeEnd(e.target.value)} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='modal-footer flex-center'>
                <button
                  type='button'
                  className='btn btn-light btn-active-light-primary fw-bold me-2'
                  data-bs-dismiss='modal'
                  onClick={() => setShowExportModal(false)}
                >
                  Cancel
                </button>
                <button
                  type='button'
                  className='btn btn-primary fw-bold'
                  onClick={() => handleExport(true)}
                  disabled={!exportRangeStart || !exportRangeEnd}
                >
                  Export range
                </button>
                <button
                  type='button'
                  className='btn btn-primary fw-bold'
                  onClick={() => handleExport(false)}
                  >
                    Export all
                  </button>
              </div>
            </div>
          </div>
        </div>
        <div className={`modal-backdrop fade ${showExportModal ? 'show' : 'pe-none'}`}></div>
      </>
    </>
  )
}

const PurchasesWrapper = () => {
  return (
    <>
      <PurchasesPage />
    </>
  )
}

export default PurchasesWrapper
