import {useContext, useEffect, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import ToolbarCustom from '../../../_metronic/layout/components/toolbar/toolbars/ToolbarCustom'

import { ProductsContext } from '../../context'
import CountryDetailModal from './CountryDetailModal'

const CountriesPage = () => {
  
  const { countries, countriesLoaded, fetchCountries, createCountry, updateCountry, deleteCountry } = useContext(ProductsContext)

  const [currentCountry, setCurrentCountry] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if(!countriesLoaded) {
      fetchCountries()
    }
  }, [countriesLoaded, fetchCountries])


  const onCreateClick = () => {
    setCurrentCountry({
      id: '',
      name: '',
      code: ''
    })
  }

  const onEditClick = (country) => {
    setCurrentCountry(country)
  }

  const onDetailSubmit = async () => {
    let response
    const data = {...currentCountry}
    if(!data.id) {
      response = await createCountry(data)
    } else {
      response = await updateCountry(data.id, data)
    }
    setCurrentCountry(null)
  }

  const onDetailDelete = async () => {
    let response = await deleteCountry(currentCountry.id)
    setCurrentCountry(null)
  }

  return (
    <>
      <ToolbarCustom pageTitle={'Countries'} primaryButton={countriesLoaded && { onClick: onCreateClick, title: 'Add' }} loading={!countriesLoaded} />
      {/* begin::Row */}
      <div className='row g-6 g-xl-9 mt-1'>
        { countriesLoaded && countries.map((country, countryIndex) => 
          <div key={`country_${country.id}`} className='col-sm-6 col-xl-6'>
            <div className='card'>
              {/* begin::Body */}
              <div className='card-body pb-0'>
                {/* begin::Header */}
                <div className='d-flex align-items-center mb-5'>
                  {/* begin::User */}
                  <div className='d-flex align-items-center flex-grow-1'>
        
                    {/* begin::Info */}
                    <div className='d-flex flex-column'>
                      <span className='text-gray-800 text-hover-primary fs-lg-4 fw-bold'>{country.name || 'Missing country name'}</span>
                    </div>
                    {/* end::Info */}
                  </div>
                  {/* end::User */}
        
                  {/* begin::Menu */}
                  <div className='my-0'>
                    <button
                      type='button'
                      className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                      onClick={() => onEditClick(country)}
                    >
                      <KTSVG path='/media/icons/duotune/general/gen055.svg' className='svg-icon-2' />
                    </button>
                  </div>
                  {/* end::Menu */}
                </div>
                {/* end::Header */}
        
                {/* begin::Post */}
                <div className='mb-5'>        
                  {/* begin::Text */}
                  <div className='text-gray-800 mb-5'>
                    { country.code }
                  </div>
                  {/* end::Text */}
                </div>
                {/* end::Post */}
              </div>
              {/* end::Body */}
            </div>
          </div>
        )}
        {/* begin::Col */}
        {/* <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          <CardsWidget20
            className='h-md-50 mb-5 mb-xl-10'
            description='Active Projects'
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
          <CardsWidget7
            className='h-md-50 mb-5 mb-xl-10'
            description='Professionals'
            icon={false}
            stats={357}
            labelColor='dark'
            textColor='gray-300'
          />
        </div> */}
        {/* end::Col */}

        {/* begin::Col */}
        {/* <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          <CardsWidget17 className='h-md-50 mb-5 mb-xl-10' />
          <ListsWidget26 className='h-lg-50' />
        </div> */}
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xxl-6'>
          {/* <EngageWidget10 className='h-md-100' /> */}
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gx-5 gx-xl-10'>
        {/* begin::Col */}
        <div className='col-xxl-6 mb-5 mb-xl-10'>
          {/* <app-new-charts-widget8 cssclassName="h-xl-100" chartHeight="275px" [chartHeightNumber]="275"></app-new-charts-widget8> */}
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xxl-6 mb-5 mb-xl-10'>
          {/* <app-cards-widget18 cssclassName="h-xl-100" image="./assets/media/stock/600x600/img-65.jpg"></app-cards-widget18> */}
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      {/* <div className='row gy-5 gx-xl-8'>
        <div className='col-xxl-4'>
          <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
        </div>
        <div className='col-xl-8'>
          <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
        </div>
      </div> */}
      {/* end::Row */}

      {/* begin::Row */}
      {/* <div className='row gy-5 g-xl-8'>
        <div className='col-xl-4'>
          <ListsWidget2 className='card-xl-stretch mb-xl-8' />
        </div>
        <div className='col-xl-4'>
          <ListsWidget6 className='card-xl-stretch mb-xl-8' />
        </div>
        <div className='col-xl-4'>
          <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />
        </div>
      </div> */}
      {/* end::Row */}

      {/* <div className='row g-5 gx-xxl-8'>
        <div className='col-xxl-4'>
          <MixedWidget8
            className='card-xxl-stretch mb-xl-3'
            chartColor='success'
            chartHeight='150px'
          />
        </div>
        <div className='col-xxl-8'>
          <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' />
        </div>
      </div> */}
      <CountryDetailModal
        show={Boolean(currentCountry)}
        handleClose={() => setCurrentCountry(null)}
        country={currentCountry}
        setCountry={setCurrentCountry}
        onSubmit={onDetailSubmit}
        onDelete={onDetailDelete} />
    </>
  )
}

const CountriesWrapper = () => {
  return (
    <>
      <CountriesPage />
    </>
  )
}

export default CountriesWrapper
