/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
  EngageWidget10,
} from '../../../_metronic/partials/widgets'
import { ArticlesContext, ContactContext, ProductsContext } from '../../context'

const DashboardPage = () => {
  const {
    articles,
    articlesLoaded,
    fetchArticles,
    fetchSections,
    // sectionsLoaded,
    // sections
  } = useContext(ArticlesContext)

    
  const {
    products,
    productsLoaded,
    fetchProducts,
    resellersLoaded,
    fetchResellers,
    resellers,
    countriesLoaded,
    fetchCountries,
    countries,
    regionsLoaded,
    fetchRegions,
    regions,
    resortsLoaded,
    fetchResorts,
    resorts
  } = useContext(ProductsContext)

  const { contactForms, contactFormsLoaded, fetchContactForms, updateContactForm } = useContext(ContactContext)

  useEffect(() => {
    if(!contactFormsLoaded) {
      fetchContactForms()
    }
  }, [contactFormsLoaded, fetchContactForms])

  useEffect(() => {
    if(!articlesLoaded) {
      fetchArticles()
    }
  }, [articlesLoaded, fetchArticles])

  // useEffect(() => {
  //   if(!sectionsLoaded) {
  //     fetchSections()
  //   }
  // }, [sectionsLoaded, fetchSections])

  useEffect(() => {
    if(!productsLoaded) {
      fetchProducts()
    }
  }, [productsLoaded, fetchProducts])

  useEffect(() => {
    if(!resellersLoaded) {
      fetchResellers()
    }
  }, [resellersLoaded, fetchResellers])

  useEffect(() => {
    if(!countriesLoaded) {
      fetchCountries()
    }
  }, [countriesLoaded, fetchCountries])

  useEffect(() => {
    if(!resortsLoaded) {
      fetchResorts()
    }
  }, [resortsLoaded, fetchResorts])

  useEffect(() => {
    if(!regionsLoaded) {
      fetchRegions()
    }
  }, [regionsLoaded, fetchRegions])

  const pendingContactForms = contactForms.filter(contactForm => !contactForm.resolved)

  return (
    <>
      {/* begin::Row */}

      {/* { pendingContactForms.length > 0 && (
        <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
          <CardsWidget7
            link='/requests'
            title={'Pending requests'}
            className='col-md-3 mb-5 mb-xl-10 bg-danger'
            textClassName='text-white'
            statsClassName='text-white fs-2x fw-bolder'
            description='Requests'
            icon={false}
            stats={pendingContactForms.length}
            labelColor='dark'
            textColor='gray-300'
            loading={!contactFormsLoaded}
          />
        </div>
      )} */}
      
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        {/* begin::Col */}
        {/* <div className='row-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'> */}
          {/* <CardsWidget20
            className='h-md-50 mb-5 mb-xl-10'
            description='Active Projects'
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          /> */}
          
          {/* <CardsWidget7
            link='/sections'
            title={'Article sections'}
            className='col-md-3 mb-5 mb-xl-10'
            description='Article sections'
            icon={false}
            labelColor='dark'
            textColor='gray-300'
            loading={!sectionsLoaded}
            stats={sections.length}

          /> */}
          
          <CardsWidget7
            link='/articles'
            title={'Articles'}
            className='col-md-3 h-md-50 mb-5 mb-xl-10'
            description='Articles'
            icon={false}
            stats={articles.length}
            loading={!articlesLoaded}
            labelColor='dark'
            textColor='gray-300'
          />
        {/* </div> */}
        {/* end::Col */}

        {/* begin::Col */}
        {/* <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'> */}
          {/* <CardsWidget17 className='h-md-50 mb-5 mb-xl-10' />
          <ListsWidget26 className='h-lg-50' /> */}
          
          <CardsWidget7
            link='/products'
            title={'Products'}
            className='col-md-3 mb-5 mb-xl-10'
            description='Products'
            icon={false}
            stats={products.length}
            labelColor='dark'
            textColor='gray-300'
            loading={!productsLoaded}
          />
          
          <CardsWidget7
            link='/network'
            title={'Network'}
            className='col-md-3 mb-5 mb-xl-10'
            description='Network'
            icon={false}
            stats={resellers.length}
            labelColor='dark'
            textColor='gray-300'
            loading={!resellersLoaded}
          />
          
          <CardsWidget7
            link='/countries'
            title={'Countries'}
            className='col-md-3 mb-5 mb-xl-10'
            description='Countries'
            icon={false}
            stats={countries.length}
            labelColor='dark'
            textColor='gray-300'
            loading={!countriesLoaded}
          />
          
          <CardsWidget7
            link='/regions'
            title={'Regions'}
            className='col-md-3 mb-5 mb-xl-10'
            description='Regions'
            icon={false}
            stats={regions.length}
            labelColor='dark'
            textColor='gray-300'
            loading={!regionsLoaded}
          />
          
          <CardsWidget7
            link='/resorts'
            title={'Resorts'}
            className='col-md-3 mb-5 mb-xl-10'
            description='Resorts'
            icon={false}
            stats={resorts.length}
            labelColor='dark'
            textColor='gray-300'
            loading={!resortsLoaded}
          />
        {/* </div> */}
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xxl-6'>
          {/* <EngageWidget10 className='h-md-100' /> */}
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gx-5 gx-xl-10'>
        {/* begin::Col */}
        <div className='col-xxl-6 mb-5 mb-xl-10'>
          {/* <app-new-charts-widget8 cssclassName="h-xl-100" chartHeight="275px" [chartHeightNumber]="275"></app-new-charts-widget8> */}
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xxl-6 mb-5 mb-xl-10'>
          {/* <app-cards-widget18 cssclassName="h-xl-100" image="./assets/media/stock/600x600/img-65.jpg"></app-cards-widget18> */}
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      {/* <div className='row gy-5 gx-xl-8'>
        <div className='col-xxl-4'>
          <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
        </div>
        <div className='col-xl-8'>
          <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
        </div>
      </div> */}
      {/* end::Row */}

      {/* begin::Row */}
      {/* <div className='row gy-5 g-xl-8'>
        <div className='col-xl-4'>
          <ListsWidget2 className='card-xl-stretch mb-xl-8' />
        </div>
        <div className='col-xl-4'>
          <ListsWidget6 className='card-xl-stretch mb-xl-8' />
        </div>
        <div className='col-xl-4'>
          <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />
        </div>
      </div> */}
      {/* end::Row */}

      {/* <div className='row g-5 gx-xxl-8'>
        <div className='col-xxl-4'>
          <MixedWidget8
            className='card-xxl-stretch mb-xl-3'
            chartColor='success'
            chartHeight='150px'
          />
        </div>
        <div className='col-xxl-8'>
          <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' />
        </div>
      </div> */}
    </>
  )
}

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export default DashboardWrapper
